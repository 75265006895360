import i18n, { t } from 'i18next';

export type ValidationFunction<T = string> = (v: T) => boolean | string;
export interface FormRule<T> {
  value: T;
  message: string;
}

export const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
export const phoneRegex = /^\+?\d{9,}$/;
export const passwordRegex = /^[0-9a-zA-Z!@#$%^&*()_+=:;№"<>?`~,.'\/|\\[\]-]+$/;

export const citizenshipRegex = /^[A-Z]{2}$/;
export const birthCertificateRegex = /^M{0,3}(D?C{0,3}|C[DM])(L?X{0,3}|X[LC])(V?I{0,3}|I[VX])\b\-?[А-Я]{2}\-?\d{6}$/;
export const passportRegex = /^\d{9}$/;
export const passportLocalRegex = /^\d{10}$/;
export const documentRegex = /^[0-9A-Za-z]+$/;
export const dateRegex = /^\d{2}\.\d{2}\.\d{4}$/;
export const bikRegex = /^\d{9}$/;
export const bankAccountRegex = /^\d{20}$/;
export const englishNameRegex = /^[A-Za-z\- ]+$/;
export const englishNameRegexStrict = /^[A-Za-z]+([\- ]?[A-Za-z]+)*$/;
export const nameRegex = /^[A-Za-zА-Яа-я\.\- ]+$/;
export const urlRegex =
  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/i;

export const required: FormRule<boolean> = { value: true, message: t('validation.required') };
export const maxLength: FormRule<any> = { value: 255, message: t('validation.maxLength') };
export const minLength: FormRule<number> = { value: 8, message: t('validation.maxLength') };
export const englishNamePattern: FormRule<RegExp> = {
  value: englishNameRegexStrict,
  message: t('validation.useLatinLettersHyphenSpaceOnly'),
};
export const namePattern: FormRule<RegExp> = {
  value: nameRegex,
  message: t('validation.useLettersDotHyphenSpaceOnly'),
};
export const passportPattern: FormRule<RegExp> = { value: passportRegex, message: t('validation.invalidPassportNumber') };
export const passportLocalPattern: FormRule<RegExp> = { value: passportLocalRegex, message: t('validation.invalidPassportNumber') };
export const documentPattern: FormRule<RegExp> = { value: documentRegex, message: t('validation.invalidDocumentNumber') };
export const emailPattern: FormRule<RegExp> = { value: emailRegex, message: t('validation.invalidEmailFormat') };
export const datePattern: FormRule<RegExp> = { value: dateRegex, message: t('validation.invalidDate') };
export const phonePattern: FormRule<RegExp> = { value: phoneRegex, message: t('validation.invalidDocumentNumber') };
export const bikPattern: FormRule<RegExp> = { value: bikRegex, message: t('validation.bikMustBeNineDigits') };
export const bankAccountPattern: FormRule<RegExp> = { value: bankAccountRegex, message: t('validation.bankAccountMustBeTwentyDigits') };
export const citizenshipPattern: FormRule<RegExp> = {
  value: citizenshipRegex,
  message: t('validation.selectOneFromList'),
};
export const birthCertificatePattern: FormRule<RegExp> = {
  value: birthCertificateRegex,
  message: t('validation.invalidBirthCertificateNumber'),
};
export const passwordPattern: FormRule<RegExp> = {
  value: passwordRegex,
  message: t('validation.passwordMustContainLatinLettersNumbersAndSymbols'),
};

i18n.on('languageChanged', () => {
  required.message = t('validation.required');
  minLength.message = t('validation.minLength');
  maxLength.message = t('validation.maxLength');
  englishNamePattern.message = t('validation.useLatinLettersHyphenSpaceOnly');
  namePattern.message = t('validation.useLettersDotHyphenSpaceOnly');
  passportPattern.message = t('validation.invalidPassportNumber');
  passportLocalPattern.message = t('validation.invalidPassportNumber');
  documentPattern.message = t('validation.invalidDocumentNumber');
  emailPattern.message = t('validation.invalidEmailFormat');
  datePattern.message = t('validation.invalidDate');
  phonePattern.message = t('validation.invalidDocumentNumber');
  bikPattern.message = t('validation.bikMustBeNineDigits');
  bankAccountPattern.message = t('validation.bankAccountMustBeTwentyDigits');
  citizenshipPattern.message = t('validation.selectOneFromList');
  birthCertificatePattern.message = t('validation.invalidBirthCertificateNumber');
  passwordPattern.message = t('validation.passwordMustContainLatinLettersNumbersAndSymbols');

});
const sortDateValue = (v: string) => {
  let currentTime = new Date();
  let datetime = v;
  if (v.indexOf('|') > -1) {
    const split = v.split('|');
    datetime = split[0].toString();
    currentTime = new Date(parseInt(split[1]) * 1000);
  }
  return { datetime, currentTime };
};
export const checkIsDateValid: ValidationFunction<string | Date> = value => {
  if (value instanceof Date && (!value.getTime || isNaN(value.getTime()))) return t('validation.invalidDate');
  // if *v* is InvalidDate
  if (typeof value === 'string') {
    const { datetime: v } = sortDateValue(value);
    if (v.indexOf('.') > -1) {
      const parts = v.split('.');
      if (
        parts.length !== 3 ||
        parts[0].length !== 2 ||
        parts[1].length !== 2 ||
        parts[2].length !== 4 ||
        parts.every(p => isNaN(parseInt(p)) && p.match(/^-?\d+$/))
      ) {
        return t('validation.invalidDateFormat');
      }
    }
    try {
      const date = new Date(v);
      // console.log(date);
      return true;
    } catch (e) {
      return t('validation.invalidDate');
    }
  }
  return true;
};

export const makeDate = (date: string) => {
  if (/\d{2}.\d{2}.\d{4}/.test(date)) {
    const day = +date.substring(0, 2);
    const month = +date.substring(3, 5);
    const year = +date.substring(6, 10);
    return new Date(year, month - 1, day, 0, 0, 0);
  } else {
    return new Date(date);
  }
};
export const checkIsDateNotInFuture: ValidationFunction<string> = str => {
  const { datetime: v } = sortDateValue(str);
  try {
    const date = makeDate(v);
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return date < now ? true : t('validation.dateInFutureNotAllowed');
  } catch (e) {
    return t('validation.invalidDate');
  }
};
export const checkIsDateNotInFutureByTicket: ValidationFunction<string> = str => {
  const { datetime: v, currentTime: now } = sortDateValue(str);
  try {
    if (!v) {
      return true;
    }
    const date = makeDate(v);
    date.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);
    return date > now ? true : t('validation.documentExpiryDateMustBeAfterArrivalDate');
  } catch (e) {
    return t('validation.invalidDate');
  }
};
export const checkIsDateAdult: ValidationFunction<string> = str => {
  const { datetime: v, currentTime: now } = sortDateValue(str);
  try {
    const date = makeDate(v);
    let checkYear = date.getFullYear() < now.getFullYear() - 12;
    if (
      !checkYear &&
      date.getFullYear() === now.getFullYear() - 12 &&
      date.getMonth() <= now.getMonth() &&
      (date.getMonth() === now.getMonth() ? date.getDate() <= now.getDate() : date.getDate() >= 1)
    ) {
      checkYear = true;
    }
    const minimalDate = new Date(1900, 0, 1, 0, 0, 0, 0);
    if (date < minimalDate) {
      return t('validation.invalidBirthYear');
    }
    return checkYear
      ? true
      : t('validation.dateCannotBeGreaterThan') + ` ${
          ('0' + now.getDate()).slice(-2) +
          '.' +
          ('0' + (now.getMonth() + 1)).slice(-2) +
          '.' +
          (now.getFullYear() - 12)
        }`;
  } catch (e) {
    return t('validation.invalidDate');
  }
};
export const checkIsDateChildren: ValidationFunction<string> = str => {
  const { datetime: v, currentTime: now } = sortDateValue(str);
  try {
    const date = makeDate(v);
    // const nowYesterday = new Date(now);
    const nowTomorrow = new Date(now);
    // nowYesterday.setDate(nowYesterday.getDate() - 1);
    nowTomorrow.setDate(nowTomorrow.getDate() + 1);

    let checkYear = date.getFullYear() > nowTomorrow.getFullYear() - 12 && date.getFullYear() < now.getFullYear() - 2;
    if (
      !checkYear &&
      date.getFullYear() === nowTomorrow.getFullYear() - 12 &&
      date.getMonth() >= nowTomorrow.getMonth() &&
      (date.getMonth() === nowTomorrow.getMonth() ? date.getDate() >= nowTomorrow.getDate() : date.getDate() >= 1)
    ) {
      checkYear = true;
    }
    if (
      !checkYear &&
      date.getFullYear() === now.getFullYear() - 2 &&
      date.getMonth() <= now.getMonth() &&
      (date.getMonth() === now.getMonth() ? date.getDate() <= now.getDate() : date.getDate() >= 1)
    ) {
      checkYear = true;
    }
    return checkYear
      ? true
      : ` ${t('validation.dateCannotBeLessThan')} ${
          ('0' + nowTomorrow.getDate()).slice(-2) +
          '.' +
          ('0' + (nowTomorrow.getMonth() + 1)).slice(-2) +
          '.' +
          (nowTomorrow.getFullYear() - 12)
        } ${t('validation.andCannotBeGreaterThan')} ${
          ('0' + now.getDate()).slice(-2) + '.' + ('0' + (now.getMonth() + 1)).slice(-2) + '.' + (now.getFullYear() - 2)
        }`;
  } catch (e) {
    return t('validation.invalidDate');
  }
};
export const checkIsDateBaby: ValidationFunction<string> = str => {
  const { datetime: v, currentTime: now } = sortDateValue(str);
  try {
    const date = makeDate(v);
    const nowTomorrow = new Date(now);
    nowTomorrow.setDate(nowTomorrow.getDate() + 1);
    let checkYear = date.getFullYear() > now.getFullYear() - 2;
    if (
      !checkYear &&
      date.getFullYear() === nowTomorrow.getFullYear() - 2 &&
      date.getMonth() >= nowTomorrow.getMonth() &&
      (date.getMonth() === nowTomorrow.getMonth() ? date.getDate() >= nowTomorrow.getDate() : date.getDate() >= 1)
    ) {
      checkYear = true;
    }
    return checkYear
      ? true
      : `${t('validation.dateCannotBeLessThan')} ${
          ('0' + nowTomorrow.getDate()).slice(-2) +
          '.' +
          ('0' + (nowTomorrow.getMonth() + 1)).slice(-2) +
          '.' +
          (nowTomorrow.getFullYear() - 2)
        }`;
  } catch (e) {
    return t('validation.invalidDate');
  }
};
export const checkIsDateNotInPast: ValidationFunction<string> = str => {
  const { datetime: v } = sortDateValue(str);
  try {
    if (!v) {
      return true;
    }
    const date = makeDate(v);
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return date > now ? true : t('validation.dateInThePastNotAllowed');
  } catch (e) {
    return t('validation.invalidDate');
  }
};
export const englishOnly: ValidationFunction<string | undefined> = v => {
  if (v === undefined) return true;
  return v && v.match(/[а-яА-Я]/) ? t('validation.onlyLatinCharactersAllowed') : true;
};
export const checkValidPhone: ValidationFunction<string | undefined> = v => {
  if (v === undefined) return true;
  return v && v.replace(/[^0-9]+/g, '').trim().length < 9 ? t('validation.invalidPhoneNumberFormat') : true;
};
export const checkValidEmail: ValidationFunction<string | undefined> = v => {
  if (v === undefined) return true;
  return v && !v.trim().match(emailRegex) ? t('validation.invalidEmailFormat') : true;
};
export const checkStringContainLetters: ValidationFunction<string | undefined> = v => {
  if (v === undefined) return true;
  return v && v.match(/^[a-zA-Z]/) ? true : t('validation.stringMustStartWithLatinLetter');
};
export const validateMaxLength: ValidationFunction<string | undefined> = v => {
  if (v === undefined || !v.length) return true;
  return v && v.trim().length <= 255 ? true : t('validation.maxLength');
};
export const validateStartEndSpaces: ValidationFunction<string | undefined> = v => {
  if (v === undefined || !v.length) return true;
  return v && v.trim() ? true : t('validation.stringCannotConsistOfSpaces');
};
export const validateCardLength: ValidationFunction<string | undefined> = v => {
  if (v === undefined) return true;
  return v && v.replace(/[_ ]/g, '').trim().length >= 16 ? true : t('validation.cardNumberMustContainAtLeast16Characters');
};
export const combineValidators =
  (fns: ValidationFunction[], tripTime?: number) =>
  (v?: string): boolean | string => {
    if (v === undefined) return true;
    for (let fn of fns) {
      if (!fn?.apply) continue; // check for a function
      const res = fn(v + (tripTime !== undefined && tripTime > 0 ? '|' + tripTime : ''));
      if (res === true) continue;
      else return res;
    }
    return true;
  };
