import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFeedback } from '../../components/FeedbackCard/FeedbackCard';
import { RootState } from '../store';
import { IPassenger } from '../../types/passenger';

interface IModalSlice {
  becomePartner: boolean;
  createPartnerLink: boolean;
  deleteLink: boolean;
  deletePassenger: boolean;
  login: boolean;
  bookingPage: boolean;
  preview: boolean;
  register: boolean;
  success: boolean;
  forgotPassword: boolean;
  createPassword: boolean;
  formInfo: boolean;
  previewOrder: boolean;
  feedbackView: boolean;
  feedbackCreate: boolean;
  id: string | null;
  payment: boolean;
  updatePassenger: boolean;
  passengerData: any;
  ordersData: any;
  language: boolean;
}
interface IModalContent {
  header?: string;
  text?: string;
  button?: string;
  isError?: boolean;
  feedback?: IFeedback;
  passenger?: IPassenger;
}

const initialState: IModalSlice & IModalContent = {
  becomePartner: false,
  createPartnerLink: false,
  deletePassenger: false,
  deleteLink: false,
  login: false,
  language: false,
  bookingPage:false,
  preview: false,
  register: false,
  success: false,
  forgotPassword: false,
  createPassword: false,
  formInfo: false,
  previewOrder: false,
  feedbackView: false,
  feedbackCreate: false,
  id: null,
  payment: false,
  updatePassenger: false,
  passengerData: null,
  ordersData: null
};
export type IModalPayload = Partial<IModalSlice>;
export type IModalContentPayload = Partial<IModalContent>;
export type IDeletePassengerPayload = { deletePassenger: boolean };

const modalSlice = createSlice({
  name: 'Modals',
  initialState,
  reducers: {
    updateModal: (state, { payload }: PayloadAction<IModalPayload>) => ({ ...state, ...payload }),
    setModalText: (state, { payload }: PayloadAction<IModalContent>) => ({ ...state, ...payload }),
    closeAll: () => initialState,
  },
});

export default modalSlice.reducer;
export const { updateModal, setModalText, closeAll } = modalSlice.actions;

export const modalSliceSelector = (state: RootState) => state.modal;
