import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import i18n from "i18next";

interface ICurrencySlice {
  currency: string | undefined;
}

const initialState: ICurrencySlice = {
  currency: localStorage.getItem('currency') ?? undefined,
};

export type ISetCurrencyPayload = { currency: string };

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setCurrency: (state, { payload: { currency } }: PayloadAction<ISetCurrencyPayload>) => {
      state.currency = currency;
      localStorage.setItem('currency', currency);
    },
  },
});

export const { setCurrency } = currencySlice.actions;
export default currencySlice.reducer;

export const currencySelector = (state: RootState) => state.currency;
