import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {PARTNER_BONUS_ON_RUB} from "./components/Partnership/PartnershipLinks/PartnershipLinks";

const BASE_PRICE = +(process.env.REACT_APP_BASE_PRICE || 1190);
const BASE_PRICE_EURO = +(process.env.REACT_APP_BASE_PRICE_EURO || 14);

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          links: {
            main: 'Home',
            tariff: 'Tariff',
            help: 'Help',
            blog: 'Blog',
            feedback: 'Feedback',
            securityAndReturnPolicy: 'Security and Return Policy',
            rules: 'Offer Agreement',
            policy: 'Privacy Policy',
          },
          common: {
            search: 'Search',
            back: 'Back',
            noResults: 'No results',
            kupitrip: 'KupiTrip',
            showMore: 'Show more',
            loading: 'Loading...'
          },
          main: {
            header: {
              part1: 'Booking and Purchase',
              part2: 'of Airline Tickets',
              account: {
                loggedIn: 'My Account',
                login: 'Log In',
              },
            },
            age: {
              adults: {
                label: 'Adults',
                description: '12 years and above'
              },
              children: {
                label: 'Children',
                description: '2 to 12 years old'
              },
              infants: {
                label: 'Infants',
                description: 'Up to 2 years old, without a seat'
              }
            },
            searchBar: {
              labels: {
                from: 'From',
                to: 'To',
                flightDate: 'Departure Date',
                returnDate: 'Return Date',
                passengersClass: 'Passengers, Class',
              },
            },
            transfers: {
              '0': 'transfers',
              '1': 'transfer',
              '2': 'transfers',
              '5': 'transfers',
            },
            passengers: {
              '0': 'passengers',
              '1': 'passenger',
              '2': 'passengers',
              '5': 'passengers',
            },
            howWorksPart1: 'How works ',
            howWorksPart2: 'KupiTrip',
            step: 'step',
            searchAndSelectARoute: 'Search and select a route',
            searchAndSelectARouteDescription: 'In the search bar, select the desired cities, dates, and number of passengers. From the suggested options, you can book any suitable flight.',
            fillingInTheData: 'Filling in the data',
            fillingInTheDataDescription: 'Provide your passport details and contact information.',
            paymentForBooking: 'Payment for booking',
            paymentForBookingDescription: `You only pay our service fee - ${BASE_PRICE_EURO}€, without worrying about the full ticket price. Payment can be made with any bank card or through SBP.`,
            validityPeriod: 'Validity period',
            upToSevenDays: 'up to 7 days',
            validityPeriodDescription: 'The booking will be available for up to 7 days; after this period, it will be canceled.',
            sendingCompletedDocuments: 'Sending completed documents',
            sendingCompletedDocumentsDescription: 'We will send the completed documents to the provided email within 30 minutes. Print them and attach them to your visa application or for any other purposes.',
            questionsAndAnswers: 'Questions and Answers',
            registrationSuccess: 'Registration Successful',
            thankYouForChoosingOurService: 'Thank you for choosing our service.',
            questionList: [
              {
                id: 1,
                question: 'How much do your services cost?',
                answer: 'You can check the cost of booking on our website in the <a href=\'/tariff\'>Tariff</a> section.'
              },
              {
                id: 2,
                question: 'How soon will I receive my booking after placing an order?',
                answer: 'The maximum booking issuance time is 30 minutes (we usually issue within 15 minutes).'
              },
              {
                id: 3,
                question: 'When is the best time to make a booking for a visa if the application is in a week?',
                answer: 'To ensure your booking is valid during the visa review, it\'s best to make it 1 day before submitting your application.'
              },
              {
                id: 4,
                question: 'Is your booking suitable for passing border control at the airport?',
                answer: 'Yes, the flight booking is also suitable for passing border control at the departure/arrival country. The flight itinerary receipt is provided in both English and Russian, which meets the requirements, and it contains a booking number that can be used to verify the ticket.'
              },
              {
                id: 5,
                question: 'Can I purchase my booked ticket to actually fly?',
                answer: 'No, you cannot purchase the booked ticket. To fly, you will need to buy a ticket on the ticket sales websites.'
              }
            ],
            moreQuestions: 'More questions',
            weAreAlwaysInTouch: 'We are always in touch!',
            anyQuestionsLeft: 'Any questions left?',
            anyQuestionsDescription: 'Our support service is available 24/7 without any days off. We will answer your questions about booking and help you place an order on the website.',
            writeToChat: 'Write in chat',
            writeToUsAt: 'Write to us on ',
            telegram: 'Telegram',
            ourSupportServiceIsAvailable: 'Our support service is available in popular messengers',
            feedbacks: 'Feedback',
            moreFeedbacks: 'All feedbacks'
          },
          tariff: {
            tariff: 'Tariff',
            airTickets: 'Air tickets',
            pricePerPassenger: 'Price per passenger:',
            rub: {
              whenPayingIn: 'When paying in rubbles:',
              description: 'When paying with a card issued by the Central Bank of the Russian Federation, the payment is in rubbles.'
            },
            eur: {
              whenPayingIn: 'When paying in euros:',
              description: 'In euros payment is made with a card issued by any bank.'
            },
            validityPeriod: 'Validity period:',
            upToDays: 'up to 7 days',
            upToDaysPopup: 'After the booking, the airline can reduce the flight ticket time limit in one direction.',
            bookingSuitable: 'Booking suitable for:',
            toApplyForAVisa: 'To apply for a visa',
            toProvideAReturnTicket: 'To provide a return ticket in the country of departure or arrival',
            forAnyOtherPurposes: 'For any other purposes where a booking is needed',
            book: 'Book',
            example: 'Booking example (PDF)',
          },
          footer: {
            onlineServiceForBooking: 'Online service for booking airline tickets for a visa',
            help: 'Help',
            popularQuestions: 'Popular questions',
            partnership: 'Partnership',
            partnershipProgram: 'Partnership program',
            forClients: 'For clients',
            tariff: 'Tariff',
            blog: 'Blog',
            feedback: 'Feedback',
            weAreOnSocialNetworks: 'We are on social networks',
            weAccept: 'We accept',
            safetyReturnPolicy: 'Return and safety policy'
          },
          searchBar: {
            selectDepartureCity: 'Select departure city',
            selectYourArrivalCity: 'Select arrival city',
            selectDate: 'Select date',
            selectAnotherArrivalCity: 'Specify different cities',
            any: 'Any',
            departure: 'From',
            arrival: 'To',
            departureDate: 'Departure Date',
            returnDate: 'Return Date',
          },
          bookingAdvice: {
            title: 'We recommend booking tickets on the day of submission at the border/for a visa.',
            description: 'We process orders 24/7 within 15 minutes!'
          },
          cookiesBanner: {
            agreement: 'By continuing to use our website, you agree to the information collection and cookie usage policy.',
            ok: 'OK'
          },
          feedbackCard: {
            hadAnswer: 'There is an answer',
            readMore: 'Read more'
          },
          filter: {
            filter: 'Filter',
            reset: 'Reset filter',
            showTickets: 'Show tickets',
            departureAndArrival: 'Departure and arrival',
            wayTo: 'OUTBOUND',
            wayReturn: 'RETURN',
            departureFrom: 'Departure from ',
            arrivalTo: 'Arrival to ',
            price: 'Price',
            transferDuration: 'Transfer duration',
            transfersList: {
              none: 'Non-stop',
              one: '1 transfer',
              more: '2 or more'
            },
            transfers: 'Transfers',
            airlines: 'Airlines',

          },
          loader: {
            findingTickets: "Searching for the best tickets for you!",
            subtitle: "Please wait........"
          },
          personalPage: {
            personalData: "Personal Data"
          },
          feedbackPage: {
            addFeedback: "Leave a Feedback",
            showMore: "Show More",
            title: "Feedback"
          },
          partnershipSettings: {
            card: {
              label: "Card Number",
              description: "By card number"
            },
            phone: {
              label: "By phone number (SBP)",
              description: "Through SBP"
            },
            req: {
              label: "By requisites",
              description: "Individual or legal entity"
            },
            personalInfo: {
              name: "Name",
              surname: "Surname",
              patronymic: "Patronymic"
            },
            bankAccount: {
              bik: "Bank BIC",
              checkingAccount: "Checking Account",
              corpAccount: "Corporate Account"
            },
            settingsSavedSuccessfully: 'Settings saved successfully',

            bankName: 'Bank Name',
            phoneLinkedToSBP: 'Phone (linked to SBP)',
            sbpBankName: 'SBP Bank Name',
            enterPhoneLinkedToSBP: 'Enter phone linked to SBP',
            recipient: 'Recipient',
            accountDetails: 'Account Details',
            save: 'Save',
            becomePartner: 'Become Partner',
            accountSettings: 'Account Settings',
            paymentMethod: 'Payment Method',
          },
          modals: {
            becomePartner: {
              registrationError: 'Registration Error',
              becomePartner: 'Become Partner',
              linkPlacement: 'Link Placement',
              link: 'Link',
              examplePlatforms: 'e.g.: Yandex Zen, personal website, YouTube channel',
              paymentMethod: 'Payment Method',
              cardNumber: 'Card Number',
              bankName: 'Bank Name',
            },
            createPartnerLink: {
              error: "Failed to create link",
              success: "Link successfully created",
              linkCopiedToClipboard: "Link successfully copied to clipboard",
              newLink: "New Link",
              linkTitle: "Link Title",
              add: "Add",
              cancel: "Cancel"
            },
            deleteLink: {
              linkDeletionSuccess: "Link successfully deleted",
              deleteLinkPrompt: "Delete link?",
              deleteLinkPromptDescription: "The link will no longer be available",
              delete: "Delete",
              cancel: "Cancel"
            },
            deletePassenger: {
              passengerDeletionSuccess: "Passenger successfully deleted",
              header: "Delete passenger?",
              description: "All personal data and document data will be deleted",
              delete: "Delete",
              cancel: "Cancel"
            },
            feedbackCreate: {
              thankYouForFeedback: "Thank you for your feedback",
              reviewPendingModeration: "Your review will be published after moderation.",
              fieldRequired: "Field is required",
              fieldMaxLength: "Maximum character length exceeded",
              name: "Name",
              surname: "Surname",
              rating: "Rating",
              feedback: "Feedback"
            },
            createPassword: {
              done: "Done!",
              passwordSaved: "Your password has been successfully saved. You will be redirected to the homepage in a few moments.",
              passwordSaveError: "Failed to save password",
              fieldRequired: "Field is required",
              passwordMinLength: "Password must be at least 8 characters long",
              createPasswordPrompt: "Create a password",
              passwordMismatch: "Passwords do not match",
              passwordLengthRequirement: "Password must be at least 8 characters long",
              passwordCriteriaTitle: "A password is secure if it contains:",
              latinLettersInDifferentCases: "Latin letters in different cases",
              digits: "Digits",
              repeatPassword: "Repeat Password",
            },
            updatePassenger: {
              passengerSuccessfullyUpdated: "Passenger successfully updated",
              adult12AndOlder: "Adult, 12 years old and older",
              adultText: "The date of birth cannot be changed as we provide search results based on passenger age type. Edit data within the passenger type or create a new search query.",
              save: "Save"
            },
            register: {
              header: "Check your email",
              text: "We have sent a registration confirmation link to ",
              emailAlreadyInUseByAnotherUser: "This email address is already in use by another user",
              register: 'Register',
              registerText: 'Enter your email and we will send a confirmation email to your address.',
              required: 'This field is required',
              minLength: 'Password must be at least 8 characters long',
              passwordRequirementLength: 'Password must be at least 8 characters long.',
              strongPasswordCriteria: 'A strong password should include:',
              latinLettersDifferentCase: 'Latin letters in different cases',
              digits: 'Digits',
              registerButton: 'Register',
              passwordMismatch: 'Passwords do not match',
              confirmPassword: 'Confirm Password',
              createPassword: 'Create a Password',
            },
            forgotPassword: {
              header: 'Change Password',
              description: 'Enter the email you used to register. We will send a link to reset your password.',
              fieldRequired: 'This field is required',
              emailLabel: 'E-mail',
              submitButton: 'Change password',
              checkYourEmail: 'Check your email',
              passwordRecoveryLinkSentTo: 'We have sent a password recovery link to',
              serverError: 'Server error',
            },
            formInfo: {
              header: 'How to Fill Out the Information',
              generalInfoTitle: 'General Information',
              generalInfoText: 'The data for booking tickets must match the passenger’s document used for travel. When entering data, all letters should be written in Latin script (using the English keyboard layout), without spaces or hyphens. Double surnames should be written as one word, without spaces or hyphens.',
              passportTitle: 'Passport',
              passportDetail1: 'As written in the document 1234567890',
              passportDetail2: 'Passport expiration date (valid until) should be provided if available',
              passportNumberLabel: 'Document Number'
            },
            paymentModal: {
              header: 'Order Payment',
              cardRuLabel: 'Russian Bank Card',
              cardRuDescription: 'Payment is only possible with a card issued by a Russian bank',
              cardLabel: 'Any Country Bank Card',
              cardDescription: 'Payment is possible with any card (except Russia). The fixed amount is in euros and does not depend on the exchange rate.',
              sbpLabel: 'SBP',
              sbpDescription: 'Fast Payment System',
              cryptoTitle: 'Cryptocurrency',
              cryptoDescriptionPart1: 'For payment, write your order number to our ',
              chatSupport: 'support chat',
              cryptoDescriptionPart2: ' or ',
              telegram: 'telegram',
              errorSelectPayment: 'Select a payment method or contact us in the support chat or telegram (for cryptocurrency)',
              rublesPaymentLabel: 'When paying in rubles',
              or: 'or',
              euroPaymentLabel: 'When paying in euros',
              securityHeader: 'How to Avoid Scammers?',
              securityTextPart1: 'Dear travelers, we remind you that when paying for services, it is important to check the authenticity of the website (',
              kupitripLink: 'https://kupitrip.online/',
              securityTextPart2: ') and the payment system (',
              best2payLink: 'https://pay.best2pay.net/',
              and: ' and ',
              stripeLink: 'https://checkout.stripe.com/',
              securityTextPart3: '). Please be cautious when asked to follow a payment link or transfer money to a phone number/card. Scammers may steal your money this way.',
              cautionText: 'Please be cautious when asked to follow a payment link or transfer money to a phone number/card. Scammers may steal your money this way.',
              payButton: 'Pay',
              agreementTextPart1: 'I consent to the ',
              agreementLink1: 'processing of personal data',
              agreementTextPart2: ', agree to the ',
              agreementLink2: 'tariff',
              agreementTextPart3: ',',
              agreementLink3: ' terms of the offer'
            },
            paymentModalAbout: {
              orderDetails: 'Order Details',
              orderNumber: 'Order Number',
              orderAmount: 'Order Amount',
              flightDirectionTo: 'Flight Direction To',
              departureDateTo: 'Departure Date To',
              flightDirectionBack: 'Flight Direction Back',
              departureDateBack: 'Departure Date Back',
              passengerCount: 'Passenger Count'
            },
            paymentModalPassengers: {
              hidePassengers: 'Hide passengers',
              showPassengers: 'Show passengers',
            },
          },

          blogPost: {
            relatedArticles: "Also read",
            share: 'Share',
            latestNews: 'Latest news:',
            viewAll: 'View all'
          },
          ticketDetails: {
            to: "TO",
            back: "BACK",
            direct: 'прямой'
          },
          noResult: {
            title: "No suitable options",
            subtitle: "Unfortunately, no flight options were found for your chosen destination. Try changing the dates and/or departure/arrival cities."
          },
          payStub: {
            title: "Payment Page",
            subtitle: "The payment page will be displayed here",
            buttonText: "Back"
          },
          passengersPage: {
            title: 'No Passengers Yet',
            subtitle: 'Create passengers to quickly add them to bookings',
            buttonText: 'Go to Home',
            addPassenger: 'Add Passenger',
            passengers: 'Passengers',
          },
          addPassengerPage: {
            addPassenger: 'Add Passenger',
            editPassenger: 'Edit Passenger',
            back: 'Back',
          },
          login: {
            loginToAccount: "Login to your account",
            password: "Password",
            register: "Register",
            login: "Log in",
            withGoogle: "With Google",
            loginError: "Login Error",
            forgotPassword: "Forgot your password?"
          },
          ticket: {
            hideDetails: 'Hide Details',
            showDetails: 'Flight Details',
            book: "Book",
            buy: "Buy",
          },
          ordersPage: {
            orders: 'Orders',
            noOrders: 'No orders yet',
            subtitle: 'You have no purchased bookings, go to the homepage to find a ticket',
            buttonText: 'Go to Homepage'
          },
          partnershipPage: {
            partnershipLinks: 'Affiliate Links',
            accountSettings: 'Account Setup'
          },
          orderDetailsPage: {
            orderDocuments: 'Order Documents',
            passengers: 'Passengers'
          },
          buying: {
            title: "Please Note!",
            note: "You are being redirected to the AIRLINE TICKET PURCHASE site (the ticket may be non-refundable). You can book the airline ticket on the previous page by clicking the 'Book' button.",
            return: "Return",
            consentToProceed: "I consent to proceed to the airline ticket purchase site",
            continue: 'Continue'
          },
          partnership: {
            steps: [
              {
                step: '1',
                mainText: 'Place your partner link',
                secondaryText: 'On your website/channel/blog',
                img: '/images/partrnership1_en.png'
              },
              {
                step: '2',
                mainText: 'The user clicks the link and makes a booking',
                img: '/images/partrnership2_en.png'
              },
              {
                step: '3',
                mainText: 'You receive your commission!',
                secondaryText: 'Commission is up to ' + PARTNER_BONUS_ON_RUB + ' ₽ per passenger',
                img: '/images/partrnership3_en.png',
                accent: true
              }
            ],
            partners: [
              { name: 'Bloggers', description: '', icon: '/icons/profile.svg' },
              {
                name: 'Website Owners',
                description: 'Recommended topics: tourism, travel, regional portals, forums, etc.',
                icon: '/icons/world.svg'
              },
              {
                name: 'Social Media Group and Channel Owners',
                description: 'Telegram, VK, Facebook, etc.',
                icon: '/icons/comment.svg'
              }
            ],
            possibilities: [
              {
                main: 'Tracking Statistics',
                description: 'Analyze the number of orders and people.',
                img: '/images/partnership_1_en.png'
              },
              {
                main: 'Creating Partner Links',
                description: 'Create multiple links for different goals and purposes.',
                img: '/images/partrnership_new_en.png'
              },
              {
                main: 'Order Analytics',
                description: 'Track popular destinations, number of passengers, and order date.',
                img: '/images/partrnership_analytics_en.png'
              },
              {
                main: 'Become a KupiTrip Partner',
                description: 'Try all the features of the personal account and start earning now!',
                img: '/images/partnership_4_en.png',
                accent: true
              }
            ],
            partnerProgram: 'Partner Program',
            earningsFromFlightBookings: 'Earn up to 80 ₽ per passenger with flight bookings!',
            becomePartner: 'Become a Partner',
            goToThePartnerProgram: 'Go to the Partner Program',
            howItWorks: 'How It Works?',
            whoCanBecomePartner: 'Who Can Become a Partner?',
            opportunities: 'Opportunities'
          },
          bookingNeedTicket: {
            statusChanged: 'Booking status has been changed',
            bookingWithTicketNumberOffer: 'If you need a ticket number at the border, we offer a service to issue a booking with an electronic ticket number. This is a purchased ticket that can be verified on the airline’s website, has a booking code, and a ticket number',
            caution1: '- The booking must be made 1 day before presentation or on the same day.',
            caution2: '- The ticket is valid for 48 hours.',
            caution3: '- You do not pay for the ticket cost.',
            pricePerPerson: 'Price per person:',
            paymentInRubles: 'When paying in rubles',
            paymentInEuros: 'When paying in euros',
            bookWithTicketNumber: 'Book with a ticket number',
            bookingWithTicketNumber: 'Booking with ticket number'

          },
          passengerBookingPrice: {
            adultBooking: 'Adult booking',
            childBooking: 'Child booking',
            babyBooking: 'Infant booking'
          },
          bookingTicket: {
            noTicketSelected: 'No ticket selected for booking'
          },
          bookingSidebar: {
            paymentInRubles: 'When paying in rubles',
            or: 'or',
            paymentInEuros: 'When paying in euros',
            airlineMayReduceTimeLimit: 'After booking, the airline may unilaterally reduce the time limit for the tickets.',
            validFor7Days: 'The booking is valid for up to 7 days',
            amountDue: 'To be paid'
          },
          orderPaymentSuccess: {
            header: 'Order paid :)',
            text: 'Thank you for your order! We will generate the itinerary receipt and send it to you separately within 30 minutes.',
            passengers: 'Passengers'
          },
          orderPaymentFail: {
            header: 'Order not paid :(',
            text: 'An error occurred with your payment, please try again.',
            proceedToPayment: 'Proceed to payment',
            toHomepage: 'To homepage'
          },
          blogPosts: {
            title: 'Travel Blog',
            all: 'All'
          },
          badGateway: {
            title: 'Oops.. we’re performing maintenance, please try again in a few minutes',
            error: 'Error 502'
          },
          flightArrow: {
            transfer: 'Transfer'
          },
          suggestion: {
            noMatches: 'No matches'
          },
          orderStatus: {
            paymentFailed: 'Payment failed',
            payed: 'Paid',
            waiting: 'Waiting for payment',
            expired: 'Expired'
          },
          orderPrice: {
            total: 'Order total'
          },
          orderPassengers: {
            numberOfPassengers: 'Number of passengers'
          },
          orderNumber: {
            orderNumber: 'Order number'
          },
          orderDocument: {
            orderDocument: 'Booking documents',
            helpMessage: 'Booking documents will be available for download in 30 minutes',
            download: 'Download'
          },
          orderDetails: {
            notUploaded: 'Ticket not uploaded',
            pay: 'Pay',
          },
          orderDate: {
            orderDate: 'Order date'
          },
          orderCard: {
            more: 'More details',
            pay: 'Pay'
          },
          previewOrder: {
            process: 'Complete',
            cancel: 'Cancel',
            noOrderError: 'No order selected for viewing'
          },
          bookingComment: {
            bookingComment: 'Order comment'
          },
          help: {
            header: 'Help'
          },
          notFound: {
            title: 'Page not found :(',
            subtitle: 'Try refreshing the page or go back to the homepage',
            buttonText: 'To homepage'
          },
          flightRoute: {
            routes: 'Routes',
            route: 'Route',
            days: 'd',
            hours: 'h',
            minutes: 'min'
          },
          passwordData: {
            oldPassword: 'Old password',
            passwordsDoNotMatch: 'Passwords do not match',
            newPassword: 'New password',
            confirmPassword: 'Confirm password',
            changePassword: 'Change Password',
          },

          contactsData: {
            phone: 'Phone',
            contactDetails: 'Contact Details',
            loggedInWithGoogle: 'You are logged in with Google',
          },

          formSubmit: {
            personalDataSaved: 'Personal data saved successfully'
          },

          personalForm: {
            alreadyTaken: 'The specified email address is already in use by another user',
            save: 'Save',
            failedToUpdatePasswordCheckOldPassword: 'Failed to update password, check old password',
            passwordNotUpdatedCheckOldValidity: 'Password not updated, check validity of old password'
          },

          citizenship: {
            Russia: 'Russia',
            Belarus: 'Belarus',
            Ukraine: 'Ukraine',
            Kazakhstan: 'Kazakhstan'
          },

          personalCitizenship: {
            popular: 'Popular',
            allCountries: 'All Countries',
            noResults: 'No matches',
          },

          passengerType: {
            passengerType: 'Passenger Type',
            adult: {
              label: 'Adult',
              description: '12 years and older',
            },
            child: {
              label: 'Child',
              description: '2 to 12 years old',
            },
            baby: {
              label: 'Infant',
              description: 'Under 2 years old, without a seat',
            },
          },

          passengerHeader: {
            passenger: 'passenger',
            howToFillData: 'How to fill in the data'
          },

          passengerForm: {
            add: 'Add',
            save: 'Save',
            cancel: 'Cancel',
            delete: 'Delete',
            added: 'Passenger successfully created',
            updated: 'Passenger successfully updated',
          },

          passengerCard: {
            gender: 'Gender',
            male: 'Male',
            female: 'Female',
            birth: 'Date of Birth',
            citizenship: 'Citizenship',
            passportId: 'Document Number',
            edit: 'Edit',
            editPassenger: 'Edit Passenger',
            save: 'Save'
          },

          placementInput: {
            enterLink: 'Enter a valid link',
            required: 'This field is required'
          },

          placementLinks: {
            successMessage: 'Placement locations saved successfully',
            errorMessage: 'Something went wrong',
            maxEntries: 'Cannot add more than 5 entries',
            placement: 'Placement',
            link: 'Link',
            save: 'Save',
            placements: 'Placement Locations',
            addPlacement: 'Add Placement Location',
            delete: 'Delete',
          },
          register: {
            register: 'Register'
          },

          contactDetails: {
            contactDetails: 'Contact Details',
            contactDetailsDescription: 'We will send the “Booking Confirmation” document to your email',
            required: 'This field is required',
            phone: 'Phone'
          },

          securityAndReturnPolicy: {
            securityAndReturnPolicy: 'Security and Return Policy',
            paymentSecurityPolicy: 'Payment Security Policy',
            paymentOptions: 'You can pay for your order with:',
            paymentOption1: '1. International payment cards Visa, MasterCard, MIR.',
            paymentOption2: '2. Through the Fast Payment System.',
            paymentOption3: '3. Yandex Pay.',
            paymentSecurityDescription: 'Payments are processed through a secure connection using TLS 1.2 protocol. Best2pay complies with international PCI DSS requirements for secure card information processing. Your confidential payment details (card details, registration information, etc.) are not sent to the website; they are processed by the Best2pay processing center and are fully protected. No one, including the website KUPITRIP, can access the payer’s banking and personal details.',
            refundPolicy: 'Refund Policy',
            refundPolicyDescription: 'In case of service refusal, the paid amount for the service will be refunded within 10 days (Article 22 of the RF Law “On Protection of Consumer Rights” N 2300-1 of 07.02 – hereinafter Law No. 2300-1).',
            attentionNotice: 'Please note!',
            serviceCancellationPolicy: 'Service cancellation is only possible before booking the flight tickets sent to your email. Refunds are made to the same bank card used for payment.'
          },
          language: {
            header: 'Language and Currency',
            language: 'Language',
            currency: 'Currency',
            russian: 'Russian',
            english: 'English',
            rubles: 'Russian Ruble (RUB)',
            euros: 'Euro (EUR)',
            confirm: 'Confirm'
          },
          validation: {
            required: 'Field is required',
            invalidDocumentNumber: 'Invalid document number',
            maxLength: 'Maximum length is 255 characters',
            minLength: 'Minimum password length is 8 characters',
            useLatinLettersHyphenSpaceOnly: 'Use only Latin letters, hyphen, or space',
            useLettersDotHyphenSpaceOnly: 'Use only letters, dot, hyphen, or space',
            invalidPassportNumber: 'Invalid passport number',
            invalidEmailFormat: 'Invalid email format',
            selectOneFromList: 'Select one item from the list',
            invalidBirthCertificateNumber: 'Invalid birth certificate number',
            passwordMustContainLatinLettersNumbersAndSymbols: 'Password must contain Latin letters (both cases), numbers, and symbols',
            invalidDate: 'Invalid date',
            invalidDateFormat: 'Invalid date format (use: dd.mm.yyyy)',
            dateInFutureNotAllowed: 'Future dates are not allowed',
            documentExpiryDateMustBeAfterArrivalDate: 'Document expiry date must be after arrival date',
            invalidBirthYear: 'Invalid birth year',
            dateCannotBeGreaterThan: 'Cannot be greater than',
            dateCannotBeLessThan: 'Cannot be less than',
            andCannotBeGreaterThan: 'and greater than',
            dateInThePastNotAllowed: 'Past dates are not allowed',
            onlyLatinCharactersAllowed: 'Only Latin characters are allowed',
            invalidPhoneNumberFormat: 'Invalid phone number format',
            stringMustStartWithLatinLetter: 'String must start with a Latin letter',
            stringCannotConsistOfSpaces: 'String cannot consist of spaces only',
            cardNumberMustContainAtLeast16Characters: 'Card number must contain at least 16 characters'
          },
          partnershipLandingPage: {
            partnershipProgramTitle: 'Kupitrip Partnership Program',
            partnershipProgramSubtitle: 'Earn up to {{amount}}₽ for each passenger booked!',
            becomePartnerButton: 'Become a Partner',
            howItWorksTitle: 'How It Works',
            whoCanPartnerTitle: 'Who Can Partner',
            opportunitiesTitle: 'Opportunities'
          },
          rules: {
            offerAgreementTitle: 'Offer Agreement',
            publicOfferTitle: 'Public Offer for Concluding a Service Agreement for Booking Airline Tickets and Other Services',
            offerText: 'This Offer is an official public proposal by "KupiTrip" IP Voronin A.N., OGRN 322237500003197 (hereinafter referred to as the Agency) to conclude an Agreement between the Client and the Agency for the provision of airline ticket booking services and other services (hereinafter referred to as the Agreement) on the Agency\'s website www.kupitrip.online. The Agreement is deemed concluded and effective from the moment the individual performs the actions provided for in the offer, which signify unconditional acceptance of all terms of the offer without any exceptions or limitations, on the terms of accession.',
            termsDefinitionsTitle: '1. Terms and Definitions',
            termsDefinitionsIntro: '1.1 In this Offer, the following terms are used with the meanings given below:',
            termOffer: '1.1.1 Offer – a proposal from the Agency addressed to any individual for concluding the Agreement under the conditions contained in this Offer.',
            termPublication: '1.1.2 Publication – the placement of the text of this Offer in free access on the Internet at www.kupitrip.online/offerta',
            termAgency: '1.1.3 Agency – "KupiTrip" IP Voronin A.N., OGRN 322237500003197',
            termAgencyNotCarrier: '1.1.4 The Agency is not an airline carrier; the Agency\'s services are intermediary in nature. The Agency undertakes to provide the Client with booking services for airline tickets on regular flights of carriers and other services from suppliers, while the Client undertakes to pay the Agency for its services at the established cost in the Tariffs section.',
            termClient: '1.1.5 Client (user) – an individual making an online booking on the Agency\'s website: www.kupitrip.online',
            termCarrier: '1.1.6 Carrier – an airline company operating air transportation of passengers and baggage, licensed to carry out the type of activity subject to licensing in accordance with the legislation of the Russian Federation, providing passenger and baggage transportation services to the temporary/permanent residence point.',
            termPassenger: '1.1.7 Passenger – an individual who has concluded an air transportation agreement or an individual for whom a charter flight agreement (aircraft charter) has been concluded.',
            termContract: '1.1.8 Contract – the agreement concluded by accepting the offer between the Client and the Agency under the conditions specified below, which is an official proposal (public offer) and contains all essential terms for providing services on the Agency\'s website www.kupitrip.online',
            termOrder: '1.1.9 Order – a properly completed request by the Client for booking airline tickets or other services on the website, including details about the transportation route, dates of departure/arrival, class of service, client\'s surname, first name, patronymic, identification document details of passengers, and contact information. In the case of international travel, the passenger\'s name and surname in Latin transcription must exactly match their spelling in the passport. The itinerary receipt of the e-ticket – part of the e-ticket containing information about the passenger transportation established by the legislation of the Russian Federation and is a document certifying the conclusion of the air transportation agreement between the carrier airline and the passenger.',
            termTimeLimit: '1.1.10 Time Limit – the period for purchasing an airline ticket or hotel, established according to the rules and regulations of the Carriers or Hotel. Carriers or hotels reserve the right to change the time limit or cancel the booking at any time.',
            termClientAction: '1.1.11 Actions by the Client for ordering electronic documents via the internet resource www.kupitrip.online, email, indicate acceptance of all the terms of this Offer Agreement. By using our Site, you confirm that you are over 18 years old, legally competent, have the right to use a bank card, and enter into contractual relations with the Agency. You take responsibility for obligations arising from the execution of this agreement. If the Client disagrees with these rules and conditions, they are not entitled to use this website for booking purposes.',
            contractSubjectTitle: '2. Subject of the Agreement',
            contractSubjectAgencyObligation: '2.1 The Agency undertakes to provide the Client with intermediary services for booking airline tickets and other services.',
            contractSubjectAgencyObligationDependent: '2.2 The Agency\'s obligation to provide the booking service under this Agreement is in all cases dependent on the actual availability of seats with the Suppliers.',
            contractSubjectAgencyObligationCompleted: '2.3 From the moment the Client receives the processed documents via email, the Agency\'s obligations to the Client are considered fulfilled, and the service is deemed provided. In case of refusal by the Client of the provided service, the paid funds are non-refundable.',
            contractSubjectAgencyResponsibility: '2.4 The Agency is not responsible for the decision of the consulate/embassy regarding the issuance of a visa or guaranteed border crossing in the departure/arrival country.',
            bookingPaymentOrderTitle: '3. Booking and Payment Procedure',
            bookingPaymentOrderAgencyObligation: '3.1 The Agency\'s obligations for booking apply to confirmed and not canceled by the Client Orders containing information provided by the Client to the Agency on the website www.kupitrip.online by independently filling out the Order form. The Client\'s Order must include the following details: transportation route, dates of departure/arrival, class of service, surname, first name, patronymic, gender of clients/passengers (in case of international transportation, the passenger\'s name and surname in Latin transcription must exactly match their spelling in the passport), identification document details of passengers, and contact information for communication with the Client: phone, email, or other means of prompt communication.',
            bookingPaymentOrderClientAcceptance: '3.2 Filling out the Order form online is an acceptance of this Agreement by the Client. The Order is an instruction from the Client to perform the assignment and contains the information specified in clause 3.1 of this Agreement.',
            bookingPaymentOrderConfirmation: '3.3 After receiving payment, the Agency sends the processed documents to the Client\'s email address. The ticket booking is processed based on the identity document of the passenger in accordance with the legislation of the Russian Federation or international agreement of the Russian Federation for the transportation route provided in the air transportation agreement. The Agency is considered to have fulfilled its obligations at the moment of sending the itinerary receipt to the Client\'s email.',
            agencyObligationsTitle: '4. Agency Responsibilities',
            agencyObligationsBooking: '4.1 The Agency undertakes to book an airline ticket or other service according to the confirmed Order of the Client (except in cases of Order cancellation).',
            clientObligationsTitle: '5. Client Responsibilities',
            clientObligationsPayment: '5.1 Make timely payment and provide accurate and complete information necessary for booking airline tickets or other services as specified in clause 3.1.',
            clientObligationsContactInfo: '5.2 Provide the Agency with accurate information about their email address and phone number necessary for the Agency to contact the Client.',
            clientObligationsInform: '5.3 Inform all passengers (in case of ordering on behalf of third parties) about the content of this Agreement and all information provided by the Agency to the Client, ensure that they fulfill all the Client\'s obligations under the Agreement. The Client guarantees having the authority to act on behalf of other individuals.',
            offerValidityTitle: '6. Offer Validity and Changes',
            offerValidityEffective: '6.1 The Offer comes into force from the moment it is published on the Agency\'s website at www.kupitrip.online and remains valid until the Offer is withdrawn by the Agency.',
            offerValidityChanges: '6.2 The Agency reserves the right to make changes to the Offer terms and/or withdraw the Offer at any time at its discretion. In case of changes to the Offer, such changes take effect from the moment of publication on the Site, unless another effective date is specified upon their publication.',
            contractDurationTitle: '7. Duration, Modification, and Termination of the Agreement',
            contractDurationEffective: '7.1 The Agreement takes effect from the moment of Acceptance of the Offer and remains valid until the Agency has fulfilled its obligations for booking the service and sending documents to the Client\'s email.',
            contractDurationTermination: '7.2 This Agreement may be terminated by mutual consent of the Parties or on other grounds provided by the current legislation of the Russian Federation.',
            contractDurationCancellation: '7.3 Service cancellation is only possible before booking the airline tickets sent to the email.',
            otherConditionsTitle: '8. Other Conditions',
            otherConditionsGoverningLaw: '8.1 All issues not regulated by the Offer or the Agreement are governed by the current legislation of the Russian Federation. Disputes and disagreements arising during the execution of this Agreement will be resolved through negotiations between the parties and in a claim procedure. The claim procedure is mandatory. The response time for claims is established in accordance with the current legislation. If disputes are not resolved, the dispute will be considered in court under Russian law.',
            otherConditionsIntegrity: '8.2 Integrity of the Agreement. The Agency does not accept any conditions and obligations regarding the subject of the Offer (Order) not specified in the Offer. Exceptions may be cases where such conditions or obligations are documented in writing and signed by the Agency and the Customer.',
            otherConditionsInvalidity: '8.3 Consequences of Invalidity of Offer Conditions. If any term of the Offer is declared invalid or void by the court, this fact does not affect the validity of the Agreement.',
            otherConditionsPersonalData: 'The Client has been informed and understands the rights of the personal data subject. Carefully read the text of the public offer.',
          },
          personalData: {
            personalData: 'Personal Data',
            surnameLabel: 'Surname (in Latin script)',
            nameLabel: 'First Name (in Latin script)',
            patronymicLabel: 'Patronymic',
            noPatronymicLabel: 'No Patronymic',
            birthDateLabel: 'Date of Birth',
            genderLabel: 'Gender',
            male: 'Male',
            female: 'Female',
          },

          bookingHeader: {
            backToSearch: 'Back to Search',
          },

          flightTag: {
            direct: 'Direct',
          },

          flightTransfer: {
            transfer: 'Transfer',
          },

          scrollStories: {
            moreDetails: 'More Details',
          },

          rangeDatePicker: {
            oneWayOnly: 'One Way Only',
          },
          partnershipLinks: {
            title: 'Partnership Links',
            newLinkButton: 'New Link',
            minPayment: 'The minimum payment amount for the partnership program is 1000 rubles. According to the terms of the partnership program, you will receive up to {{amount}} rubles for each passenger in the order.',
            passengers: 'Passengers',
            orders: 'Orders',
            income: 'Income',
            expectedPayout: 'Expected payout at the end of the month',
            loading: 'Loading...',
            noLinksTitle: 'No links created',
            noLinksSubtitle: 'Create your first link to start using the partnership program',
            sortOptions: {
              allTime: 'All time',
              today: 'Today',
              sevenDays: '7 days',
              thirtyDays: '30 days'
            }
          },

          partnershipLinkDetails: {
            linkCopied: 'Link successfully copied to clipboard',
            noLinkData: 'No data available for the link',
            copyLink: 'Copy link',
            deleteLink: 'Delete link',
            passengers: 'Passengers',
            orders: 'Orders',
            income: 'Income',
            noCurrentOrdersTitle: 'No current orders',
            noCurrentOrdersSubtitle: 'No orders have been placed for this time period'
          },
          ordersLinksTable: {
            sort: 'Sort',
            passengers: 'Passengers',
            date: 'Date',
            income: 'Income',
            apply: 'Apply',
            descending: 'Descending',
            ascending: 'Ascending',
            direction: 'Direction',
          },
          linksTable: {
            sort: 'Sort',
            descending: 'Descending',
            ascending: 'Ascending',
            apply: 'Apply',
            orders: 'Orders',
            passengers: 'Passengers',
            income: 'Income'
          },
          stepCard: {
            step: 'step'
          },
          personalDocument: {
            passportData: 'Passport Data',
            citizenship: 'Citizenship',
            document: 'Document',
            passportNumber: 'Document Number',
            validity: 'Validity Period',
            hasValidity: 'Validity Period',
            foreignPassportRF: 'Russian foreign passport',
            foreignPassport: 'Foreign document',
            internalPassportRF: 'Russian internal passport',
          },
          profileMenu: {
            partnerUser: 'Partner User',
            personalData: 'Personal Data',
            orders: 'Orders',
            passengers: 'Passengers',
            partnershipProgram: 'Partnership Program',
            logout: 'Logout'
          },
          confidentialPolicy: {
            processingPersonalData: 'Processing Personal Data',
            generalProvisions: '1. General Provisions',
            policyText1: 'This personal data processing policy is drafted in accordance with the requirements of Federal Law No. 152-FZ of 27.07.2006 “On Personal Data” and defines the procedures for processing personal data and measures to ensure the security of personal data for "Kupitrip" IP Voronin A.N OGRN 322237500003197 (hereinafter referred to as the Operator).',
            policyText2: 'The Operator’s primary goal and condition for conducting its activities is to respect human and citizen rights and freedoms when processing personal data, including the protection of the right to privacy and personal and family secrets. This Policy of the Operator regarding personal data processing (hereinafter referred to as the Policy) applies to all information that the Operator may receive about visitors to the website https://kupitrip.online.',
            termsDefinitions: '2. Key Terms Used in the Policy',
            automatedProcessing: 'Automated Processing of Personal Data',
            automatedProcessingDescription: 'Processing of personal data using computing technology',
            blockingData: 'Blocking of Personal Data',
            blockingDataDescription: 'Temporary cessation of processing personal data (except in cases where processing is necessary to clarify personal data)',
            website: 'Website',
            websiteDescription: 'A set of graphic and informational materials, as well as software and databases, ensuring their availability on the Internet at the network address https://kupitrip.online',
            infoSystem: 'Personal Data Information System',
            infoSystemDescription: 'A set of personal data contained in databases and information technologies and technical means ensuring their processing',
            dataAnonymization: 'Anonymization of Personal Data',
            dataAnonymizationDescription: 'Actions that make it impossible to identify the personal data of a specific User or another data subject without additional information',
            dataProcessing: 'Processing of Personal Data',
            dataProcessingDescription: 'Any action (operation) or set of actions (operations) performed using automation tools or without such tools with personal data, including collection, recording, systematization, accumulation, storage, clarification (update, modification), extraction, use, transfer (distribution, provision, access), anonymization, blocking, deletion, destruction of personal data',
            operator: 'Operator',
            operatorDescription: 'A state authority, municipal authority, legal entity, or individual that independently or jointly with others organizes and/or carries out the processing of personal data, as well as determines the purposes of processing personal data, the composition of personal data to be processed, and the actions (operations) performed with personal data',
            personalData: 'Personal Data',
            personalDataDescription: 'Any information directly or indirectly related to a specific or identifiable User of the website https://kupitrip.online',
            user: 'User',
            userDescription: 'Any visitor to the website https://kupitrip.online',
            dataProvision: 'Provision of Personal Data',
            dataProvisionDescription: 'Actions aimed at disclosing personal data to a specific person or a specific group of persons',
            dataDistribution: 'Distribution of Personal Data',
            dataDistributionDescription: 'Any actions aimed at disclosing personal data to an indefinite group of persons (transferring personal data) or making personal data available to an unlimited group of persons, including publishing personal data in mass media, placing it in information and telecommunication networks, or providing access to personal data in any other way',
            crossBorderTransfer: 'Cross-Border Transfer of Personal Data',
            crossBorderTransferDescription: 'Transfer of personal data to a foreign state authority, foreign individual, or foreign legal entity',
            dataDestruction: 'Destruction of Personal Data',
            dataDestructionDescription: 'Any actions resulting in the irreversible destruction of personal data with no possibility of further recovery of the content of personal data in the personal data information system and/or the destruction of physical carriers of personal data',
            dataProcessingPurpose: '3. The Operator May Process the Following Personal Data of the User',
            dataProcessingPurposeText1: 'Last name, first name, patronymic',
            dataProcessingPurposeText2: 'Email address',
            dataProcessingPurposeText3: 'Phone numbers',
            dataProcessingPurposeText4: 'Year, month, date, and place of birth',
            dataProcessingPurposeText5: 'Identity document details',
            dataProcessingPurposeText6: 'The site also collects and processes anonymized data about visitors (including cookies) using internet statistics services (Yandex Metrica, Google Analytics, and others)',
            dataProcessingPurposeText7: 'The above data is collectively referred to as Personal Data in this Policy',
            dataProcessingPurposeText8: 'The purpose of processing User’s personal data is to inform the User by sending emails',
            dataProcessingPurposeText9: 'The Operator also has the right to send the User notifications about new products and services, special offers, and various events',
            dataProcessingPurposeText10: 'The User may always opt-out of receiving informational messages by sending the Operator an email to info@kupitrip.online with the subject “Opt-out of notifications about new products and services and special offers”',
            dataProcessingPurposeText11: 'Anonymized data of Users, collected using internet statistics services, is used to gather information about User actions on the site, improve the quality of the site and its content',
            legalBasis: '5. Legal Grounds for Processing Personal Data',
            legalBasisText1: 'The Operator processes the User’s personal data only if it is filled out and/or sent by the User independently through special forms on the website https://kupitrip.online.',
            legalBasisText2: 'By filling out the relevant forms and/or sending their personal data to the Operator, the User agrees to this Policy.',
            legalBasisText3: 'The Operator processes anonymized data about the User if permitted by the User’s browser settings (cookie saving and JavaScript technology enabled).',
            processingOrder: '6. Procedure for Collecting, Storing, Transferring, and Other Types of Processing Personal Data',
            processingOrderText1: 'The security of personal data processed by the Operator is ensured by implementing legal, organizational, and technical measures necessary to fully comply with the requirements of current legislation on personal data protection.',
            processingOrderText2: 'The Operator ensures the security of personal data and takes all possible measures to prevent unauthorized access to personal data.',
            processingOrderText3: 'The User’s personal data will never, under any circumstances, be transferred to third parties, except in cases related to the implementation of current legislation.',
            processingOrderText4: 'In case of inaccuracies in personal data, the User can update it independently by sending a notification to the Operator’s email address info@kupitrip.online with the subject “Personal Data Update”.',
            processingOrderText5: 'The period for processing personal data is unlimited.',
            processingOrderText6: 'The User can withdraw their consent to the processing of personal data at any time by sending a notification to the Operator via email at info@kupitrip.online with the subject “Withdrawal of Consent to Personal Data Processing”.',
            crossBorderTransferText1: 'Before commencing cross-border transfer of personal data, the Operator must ensure that the foreign state to which the transfer is planned provides reliable protection for the rights of personal data subjects.',
            crossBorderTransferText2: 'Cross-border transfer of personal data to foreign states that do not meet the above requirements may only be carried out if there is written consent from the personal data subject for the cross-border transfer of their personal data and/or the execution of a contract to which the personal data subject is a party.',
            finalProvisions: '8. Final Provisions',
            finalProvisionsText1: 'The User can obtain any clarifications regarding their personal data processing by contacting the Operator via email at info@kupitrip.online.',
            finalProvisionsText2: 'This document will reflect any changes to the Operator’s personal data processing policy. The Policy is valid indefinitely until replaced by a new version.',
            finalProvisionsText3: 'The current version of the Policy is freely available on the Internet at https://kupitrip.online/confedential_policy.'
          },
          searchTypeEnum: {
            eco: 'Economy',
            biz: 'Business',
          },
          passengersDropdown: {
            adults: 'Adults',
            adultsDescription: '12 years and older',
            children: 'Children',
            childrenDescription: 'Ages 2 to 12',
            babies: 'Infants',
            babiesDescription: 'Under 2 years, no seat'
          },
          loginCard: {
            signIn: 'Log in',
            loginToAccount: 'Login to your account',
            buttonLogin: 'Login',
          },
          partnershipLinksTable: {
            name: 'Name/Link',
            ordersTotal: 'Orders',
            passengersTotal: 'Passengers',
            incomeTotal: 'Income',
          },
          passengerTypes: {
            adult: 'adult',
            child: 'child',
            baby: 'infant'
          },
          images: {
            price: '/images/3png@3x2_en.png',
            search: '/images/1png@3x_en.png',
            passengerInfo: '/images/2png@3x_en.png',
            pdf: '/images/1png1@2x_en.png',
            telegram: '/images/1png3@3x_en.png',
          },
          titles: {
            bookingAirlineTicketsForVisa: 'Booking airline tickets for a visa',
            fareAndPriceForKupiTripBooking: 'Fare and price for KupiTrip booking / KupiTrip reservation',
            howMuchDoKupiTripServicesCost: 'How much do KupiTrip services cost?',
            kupiTripBookingReviews: 'KupiTrip booking, feedback'
          },
          app: {
            createPassword: {
              header: 'Create Password',
              text: 'Registration is almost complete, just create a password for your account',
              button: 'Create'
            },
            changePassword: {
              header: 'Change Password',
              text: 'Enter a new password',
              button: 'Save'
            },
            invalidLink: {
              header: 'Invalid Link',
              text: 'The link is incorrect. Get a new link'
            },
            metaDescriptions: {
              bookingAirlineTicketsForVisa: 'Booking airline tickets for a visa without ticket payment. Ticket reservation without payment for 1190 rubles. Official reservation. Online booking of tickets for a visa.',
              fareAndPriceForKupiTripBooking: 'Tariff and price for KupiTrip booking/KupiTrip reservation.',
              kupiTripBookingReviews: 'Reviews about KupiTrip service. KupiTrip flight booking reviews.',
              frequentlyAskedQuestionsKupiTrip: 'Frequently asked questions about KupiTrip service. How much do KupiTrip services cost? Can I cancel my KupiTrip reservation? Official reservation.'
            }
          },
        },
      },
      ru: {
        translation: {
          links: {
            main: 'Главная',
            tariff: 'Тариф',
            help: 'Помощь',
            blog: 'Блог',
            feedback: 'Отзывы',
            securityAndReturnPolicy: 'Политика безопасности и возврата',
            rules: 'Договор оферты',
            policy: 'Политика конфиденциальности'
          },
          common: {
            search: 'Найти',
            back: 'Назад',
            noResults: 'Нет результатов',
            kupitrip: 'КупиТрип',
            showMore: 'Показать ещё',
            loading: 'Идет загрузка...',
          },
          main: {
            header: {
              part1: 'Бронирование и покупка',
              part2: 'авиабилетов',
              account: {
                loggedIn: 'Мой профиль',
                login: 'Войти',
              },
            },
            age: {
              adults: {
                label: 'Взрослые',
                description: '12 лет и старше'
              },
              children: {
                label: 'Дети',
                description: 'от 2 до 12 лет'
              },
              infants: {
                label: 'Младенцы',
                description: 'До 2 лет, без места'
              }
            },
            searchBar: {
              labels: {
                from: 'Откуда',
                to: 'Куда',
                flightDate: 'Дата туда',
                returnDate: 'Дата обратно',
                passengersClass: 'Пассажиры, класс',
              },
            },
            transfers: {
              '0': 'пересадок',
              '1': 'пересадка',
              '2': 'пересадки',
              '5': 'пересадок',
            },
            passengers: {
              '0': 'пассажиров',
              '1': 'пассажир',
              '2': 'пассажира',
              '5': 'пассажиров',
            },
            howWorksPart1: 'Как работает ',
            howWorksPart2: 'КупиТрип',
            step: 'шаг',
            searchAndSelectARoute: 'Поиск и выбор маршрута',
            searchAndSelectARouteDescription: 'В строке поиска выберите нужные города, даты и количество пассажиров. Из предложенных вариантов вы можете забронировать любой подходящий вариант перелета.',
            fillingInTheData: 'Заполнение данных',
            fillingInTheDataDescription: 'Укажите данные загранпаспорта и контактную информацию.',
            paymentForBooking: 'Оплата бронирования',
            paymentForBookingDescription: `Вы оплачиваете только наш сервисный сбор - ${BASE_PRICE}₽ или ${BASE_PRICE_EURO}€, не думая о полной стоимости билета. Оплатить можно любой банковской картой или через СБП.`,
            validityPeriod: 'Срок действия',
            upToSevenDays: 'до 7 дней',
            validityPeriodDescription: 'Бронирование будет доступно до 7 дней, после истечения срока оно аннулируется',
            sendingCompletedDocuments: 'Отправка готовых документов',
            sendingCompletedDocumentsDescription: 'Мы отправим готовые документы на указанную электронную почту в течение 30 минут. Распечатайте и приложите их к заявлению на визу или для любых других целей.',
            questionsAndAnswers: 'Вопросы и ответы',
            registrationSuccess: 'Успешная регистрация',
            thankYouForChoosingOurService: 'Спасибо, что выбрали наш сервис.',
            questionList: [
              {
                id: 1,
                question: 'Сколько стоят ваши услуги?',
                answer: 'Стоимость бронирования вы можете посмотреть на нашем сайте в разделе <a href=\'/tariff\'>Тариф</a>',
              },
              {
                id: 2,
                question: 'Через сколько я получу бронь после оформления заказа?',
                answer: 'Максимальное время выписки брони билета 30 минут (обычно выписываем в течение 15 минут).',
              },
              {
                id: 3,
                question: 'Когда лучше делать бронирование для визы, если подача на визу через неделю?',
                answer: 'Чтобы бронь держалась в момент рассмотрения визы, вам лучше оформить её за 1 день до подачи.',
              },
              {
                id: 4,
                question: 'Подходит ли ваша бронь для прохождения пограничного контроля в аэропорту?',
                answer:
                  'Да, бронь авиабилета подходит также для прохождения пограничного контроля в стране вылета/прилета. Маршрутная квитанция авиабилета предоставляется на английском и русском языке, что соответствует правилам, а также содержит номер бронирования по которому можно проверить билет.',
              },
              {
                id: 5,
                question: 'Можно ли выкупить свою бронь билета для осуществления полета?',
                answer:
                  'Нет, бронь билета нельзя выкупить. Для осуществления полета вы можете купить билет на сайтах по продаже билетов.',
              },
            ],
            moreQuestions: 'Больше вопросов',
            weAreAlwaysInTouch: 'Мы всегда на связи!',
            anyQuestionsLeft: 'Остались вопросы?',
            anyQuestionsDescription: 'Служба поддержки работает круглосуточно и без выходных. Ответим на вопросы по бронированию и подскажем как оформить заказ на сайте.',
            writeToChat: 'Написать в чат',
            writeToUsAt: 'Напишите нам в ',
            telegram: 'Телеграме',
            ourSupportServiceIsAvailable: 'Наша служба поддержки есть в популярных мессенджерах',
            feedbacks: 'Отзывы',
            moreFeedbacks: 'Все отзывы',

          },
          tariff: {
            tariff: 'Тариф',
            airTickets: 'Авиабилеты',
            pricePerPassenger: 'Цена за 1 пассажира:',
            rub: {
              whenPayingIn: 'При оплате в рублях:',
              description: 'При оплате картой выпущенной банком РФ оплата в рублях'

            },
            eur: {
              whenPayingIn: 'При оплате в евро:',
              description: 'В евро оплата производится картой, выпущенной банком любой страны.'
            },
            validityPeriod: 'Срок действия:',
            upToDays: 'до 7 дней',
            upToDaysPopup: 'После бронирования авиакомпания может уменьшить тайм-лимит по авиабилетам в одностороннем порядке',
            bookingSuitable: 'Бронь подойдет:',
            toApplyForAVisa: 'Для получения визы',
            toProvideAReturnTicket: 'Для предоставления обратного билета в стране вылета\\прилета',
            forAnyOtherPurposes: 'Для любых других целей, где нужна бронь',
            book: 'Забронировать',
            example: 'Пример брони (PDF)',
          },
          footer: {
            onlineServiceForBooking: 'Онлайн-сервис бронирования авиабилетов для визы',
            help: 'Помощь',
            popularQuestions: 'Популярные вопросы',
            partnership: 'Сотрудничество',
            partnershipProgram: 'Партнерская программа',
            forClients: 'Клиентам',
            tariff: 'Тариф',
            blog: 'Блог',
            feedback: 'Отзывы',
            weAreOnSocialNetworks: 'Мы в социальных сетях',
            weAccept: 'Мы принимаем',
            safetyReturnPolicy: 'Политика возврата и безопасности',
          },
          searchBar: {
            selectDepartureCity: 'Выберите город вылета',
            selectYourArrivalCity: 'Выберите город прибытия',
            selectDate: 'Выберите дату',
            selectAnotherArrivalCity: 'Укажите разные города',
            any: 'Любой',
            departure: 'Откуда',
            arrival: 'Куда',
            departureDate: 'Дата туда',
            returnDate: 'Дата обратно',
          },
          bookingAdvice: {
            title: 'Советуем оформлять бронь билетов в день предъявления на границе/для визы.',
            description: 'Мы оформляем заказы круглосуточно в течение 15 минут!',
          },
          cookiesBanner: {
            agreement: 'Продолжая использовать наш сайт, вы соглашаетесь с правилами сбора информации и использования cookie',
            ok: 'Хорошо'
          },
          feedbackCard: {
            hadAnswer: 'Есть ответ',
            readMore: 'Читать полностью',
          },
          filter: {
            filter: 'Фильтр',
            reset: 'Сбросить фильтр',
            showTickets: 'Показать билеты',
            departureAndArrival: 'Вылет и прибытие',
            wayTo: 'ТУДА',
            wayReturn: 'ОБРАТНО',
            departureFrom: 'Вылет из ',
            arrivalTo: 'Прибытие в ',
            price: 'Цена',
            transferDuration: 'Длительность пересадки',
            transfersList: {
              none: 'Без пересадок',
              one: '1 пересадка',
              more: '2 и более',
            },
            transfers: 'Пересадки',
            airlines: 'Авиакомпании',
          },
          loader: {
            findingTickets: 'Ищем для вас лучшие билеты!',
            subtitle: 'Пожалуйста, подождите........',
          },
          personalPage: {
            personalData: 'Персональные данные'
          },
          feedbackPage: {
            addFeedback: 'Оставить отзыв',
            showMore: 'Показать еще',
            title: 'Отзывы',
          },
          partnershipSettings: {
            card: {
              label: 'Номер карты',
              description: 'По номеру карты',
            },
            phone: {
              label: 'По номеру телефона (СБП)',
              description: 'Через СПБ',
            },
            req: {
              label: 'По реквизитам',
              description: 'Физическое или юридическое лицо',
            },
            personalInfo: {
              name: 'Имя',
              surname: 'Фамилия',
              patronymic: 'Отчество',
            },
            bankAccount: {
              bik: 'БИК банка',
              checkingAccount: 'Расчётный счёт',
              corpAccount: 'Корпоративный счёт',
            },
            settingsSavedSuccessfully: 'Настройки успешно сохранены',

            bankName: 'Наименование банка',
            phoneLinkedToSBP: 'Телефон (привязанный к СБП)',
            sbpBankName: 'Наименование банка',
            enterPhoneLinkedToSBP: 'Введите телефон привязанный к системе быстрых платежей',
            recipient: 'Получатель',
            accountDetails: 'Данные счёта',
            save: 'Сохранить',
            becomePartner: 'Стать партнером',
            accountSettings: 'Настройки кабинета',
            paymentMethod: 'Способ выплаты'
          },
          modals: {
            becomePartner: {
              registrationError: 'Ошибка регистрации',
              becomePartner: 'Стать партнером',
              linkPlacement: 'Место размещения ссылки',
              link: 'Ссылка',
              examplePlatforms: 'Например: Яндекс Дзен, личный сайт, YouTube канал',
              paymentMethod: 'Способ выплаты',
              cardNumber: 'Номер карты',
              bankName: 'Наименование банка',
            },
            createPartnerLink: {
              error: 'Не удалось создать ссылку',
              success: 'Ссылка успешно создана',
              linkCopiedToClipboard: 'Ссылка успешно скопирована в буфер обмена',
              newLink: 'Новая ссылка',
              linkTitle: 'Название ссылки',
              add: 'Добавить',
              cancel: 'Отмена',
            },
            deleteLink: {
              linkDeletionSuccess: 'Ссылка успешно удалена',
              deleteLinkPrompt: 'Удалить ссылку?',
              deleteLinkPromptDescription: 'Ссылка станет больше не доступна',
              delete: 'Удалить',
              cancel: 'Отмена',
            },
            deletePassenger: {
              passengerDeletionSuccess: 'Пассажир успешно удален',
              header: 'Удалить пассажира?',
              description: 'Удалятся все персональные данные и данные документа',
              delete: 'Удалить',
              cancel: 'Отмена',
            },
            feedbackCreate: {
              thankYouForFeedback: 'Спасибо за отзыв',
              reviewPendingModeration: 'После модерации ваш отзыв будет опубликован.',
              fieldRequired: 'Поле обязательно',
              fieldMaxLength: 'Превышено максимальное количество символов',
              name: 'Имя',
              surname: 'Фамилия',
              rating: 'Оценка',
              feedback: 'Отзыв',
            },
            createPassword: {
              done: 'Готово!',
              passwordSaved: `Ваш пароль успешно сохранен. Вы будете перенаправлены на главную через несколько мгновений`,
              passwordSaveError: 'Не удалось сохранить пароль',
              fieldRequired: 'Поле обязательно',
              passwordMinLength: 'Минимальная длина пароля 8 символов',
              createPasswordPrompt: 'Придумайте пароль',
              passwordMismatch: 'Пароль не совпадает',
              passwordLengthRequirement: 'Пароль должен содержать не менее 8 символов',
              passwordCriteriaTitle: 'Пароль надежный, если содержит:',
              latinLettersInDifferentCases: 'Латинские буквы в разном регистре',
              digits: 'Цифры',
              repeatPassword: 'Повторите пароль',
            },
            updatePassenger: {
              passengerSuccessfullyUpdated: 'Пассажир успешно обновлен',
              adult12AndOlder: 'Взрослый, 12 лет и старше',
              adultText: 'Дату рождения изменить нельзя т.к мы выдаем поисковую выдачу по типу возраста пассажира. Редактируйте данные в рамках типа пассажира или сформируйте новый поисковый запрос.',
              save: 'Сохранить',
            },
            register: {
              header: 'Проверьте почту',
              text: 'Мы отправили ссылку для подтверждения регистрации на ',
              emailAlreadyInUseByAnotherUser: 'Данный e-mail адрес уже используется другим пользователем',
              register: 'Регистрация',
              registerText: 'Введите e-mail и мы отправим письмо для подтверждения на вашу электронную почту.',
              required: 'Поле обязательно',
              minLength: 'Минимальная длина пароля 8 символов',
              passwordRequirementLength: 'Пароль должен содержать не менее 8 символов.',
              strongPasswordCriteria: 'Пароль надежный, если содержит:',
              latinLettersDifferentCase: 'Латинские буквы в разном регистре',
              digits: 'Цифры',
              registerButton: 'Зарегистрироваться',
              passwordMismatch: 'Пароль не совпадает',
              confirmPassword: 'Повторите пароль',
              createPassword: 'Придумайте пароль'
            },
            forgotPassword: {
              header: 'Смена пароля',
              description: 'Укажите почту, с которой вы регистрировались. Мы вышлем ссылку для смены пароля.',
              fieldRequired: 'Поле обязательно',
              emailLabel: 'E-mail',
              submitButton: 'Сменить пароль',
              checkYourEmail: 'Проверьте почту',
              passwordRecoveryLinkSentTo: 'Мы отправили ссылку для восстановления пароля на',
              serverError: 'Ошибка сервера',
            },
            formInfo: {
              header: 'Как заполнить данные',
              generalInfoTitle: 'Общая информация',
              generalInfoText: 'Данные для оформления брони билетов должны соответствовать документу пассажира, по которому он собирается путешествовать. При вводе данных все буквы должны быть написаны латиницей (в английской раскладке клавиатуры), без пробелов и дефисов. Двойная фамилия указывается слитно, без пробелов и дефисов.',
              passportTitle: 'Паспорт',
              passportDetail1: 'Написание как в документе  1234567890',
              passportDetail2: 'Срок действия паспорта (до какого годен) указывается при наличии',
              passportNumberLabel: 'Номер документа'
            },
            paymentModal: {
              header: 'Оплата заказа',
              cardRuLabel: 'Банковской картой РФ',
              cardRuDescription: 'Оплата возможна только картой выпущенной банком РФ',
              cardLabel: 'Банковской картой любой страны',
              cardDescription: 'Оплата возможна только картой любой страны (кроме РФ). Стоимость указана в евро фиксированная, она не зависит от курса.',
              sbpLabel: 'Через СБП',
              sbpDescription: 'Система быстрых платежей',
              cryptoTitle: 'Криптовалютой',
              cryptoDescriptionPart1: 'Для оплаты напишите номер заказа в наш ',
              chatSupport: 'чат поддержки',
              cryptoDescriptionPart2: ' или ',
              telegram: 'телеграм',
              errorSelectPayment: 'Выберите способ оплаты или напишите нам в чат поддержки или телеграм (для криптовалюты)',
              rublesPaymentLabel: 'При оплате в рублях:',
              or: 'или',
              euroPaymentLabel: 'При оплате в евро:',
              securityHeader: 'Как не попасться на мошенников?',
              securityTextPart1: 'Уважаемые путешественники, напоминаем вам, что при оплате услуг важно проверять подлинность сайта (',
              kupitripLink: 'https://kupitrip.online/',
              securityTextPart2: ') и системы оплаты (',
              best2payLink: 'https://pay.best2pay.net/',
              and: ' и ',
              stripeLink: 'https://checkout.stripe.com/',
              securityTextPart3: '). Пожалуйста, будьте внимательны, когда вас просят перейти по ссылке для оплаты услуг или осуществить перевод по номеру телефона/карты. Мошенники могут таким способом украсть ваши деньги.',
              cautionText: 'Пожалуйста, будьте внимательны, когда вас просят перейти по ссылке для оплаты услуг или осуществить перевод по номеру телефона/карты. Мошенники могут таким способом украсть ваши деньги.',
              payButton: 'Оплатить',
              agreementTextPart1: 'Даю согласие на ',
              agreementLink1: 'обработку персональных данных',
              agreementTextPart2: ', согласен(а) с ',
              agreementLink2: 'тарифом',
              agreementTextPart3: ',',
              agreementLink3: ' условиями договора-оферты',
            },
            paymentModalAbout: {
              orderDetails: 'О заказе',
              orderNumber: 'Номер заказа',
              orderAmount: 'Сумма заказа',
              flightDirectionTo: 'Направление перелета, туда',
              departureDateTo: 'Дата вылета, туда',
              flightDirectionBack: 'Направление перелета, обратно',
              departureDateBack: 'Дата вылета, обратно',
              passengerCount: 'Кол-во пассажиров'
            },
            paymentModalPassengers: {
              hidePassengers: 'Скрыть пассажиров',
              showPassengers: 'Показать пассажиров',
            },
          },
          blogPost: {
            relatedArticles: 'Читайте также',
            share: 'Поделиться',
            latestNews: 'Последние новости:',
            viewAll: 'Смотреть все',
          },
          ticketDetails: {
            to: 'ТУДА',
            back: 'ОБРАТНО',
            direct: 'direct',
          },
          noResult: {
            title: "Нет подходящих вариантов",
            subtitle: "К сожалению, по выбранному вами направлению не найдено вариантов перелета. Попробуйте изменить даты и/или города вылета/прилета."
          },
          payStub: {
            title: "Страница оплаты",
            subtitle: "Тут будет отображаться страница оплаты",
            buttonText: "Назад"
          },
          passengersPage: {
            title: 'Пассажиров пока нет',
            subtitle: 'Создайте пассажиров, чтобы быстро добавлять их в бронирования',
            buttonText: 'На главную',
            addPassenger: 'Добавить пассажира',
            passengers: 'Пассажиры',
          },
          addPassengerPage: {
            addPassenger: 'Добавить пассажира',
            editPassenger: 'Редактировать пассажира',
            back: 'Назад',
          },
          login: {
            loginToAccount: 'Войти в личный кабинет',
            password: 'Пароль',
            register: 'Зарегистрироваться',
            login: 'Войти',
            withGoogle: 'С помощью Google',
            loginError: 'Ошибка входа',
            forgotPassword: 'Забыли пароль?',
          },
          ticket: {
            hideDetails: 'Скрыть детали',
            showDetails: 'Детали перелета',
            book: 'Забронировать',
            buy: 'Купить',
          },
          ordersPage: {
            orders: 'Заказы',
            noOrders: 'Заказов пока нет',
            subtitle: 'У вас нет купленных бронирований, перейдите на главную для поиска билета',
            buttonText: 'На главную'
          },
          partnershipPage: {
            partnershipLinks: 'Партнерские ссылки',
            accountSettings: 'Настройка кабинета'
          },
          orderDetailsPage: {
            orderDocuments: 'Документы заказа',
            passengers: 'Пассажиры',
          },
          buying: {
            title: 'Обратите внимание!',
            note: 'Вы переходите на сайт ПОКУПКИ авиабилета (билет может быть невозвратным), бронирование авиабилета вы можете совершить на предыдущей странице по кнопке "Забронировать"',
            return: 'Вернуться',
            consentToProceed: 'Даю согласие на переход на сайт покупки авиабилета',
            continue: 'Продолжить',
          },
          partnership: {
            steps: [
              {
                step: '1',
                mainText: 'Размещаете партнерскую ссылку',
                secondaryText: 'У себя на сайте/канале/блоге',
                img: '/images/partrnership1.png',
              },
              {
                step: '2',
                mainText: 'Пользователь переходит по ссылке и оформляет бронирование',
                img: '/images/partrnership2.png',
              },
              {
                step: '3',
                mainText: 'Вы получаете свою комиссию!',
                secondaryText: 'Комиссия составляет до ' + PARTNER_BONUS_ON_RUB + '₽ за каждого пассажира',
                img: '/images/partrnership3.png',
                accent: true,
              },
            ],
            partners: [
              { name: 'Блогеры', description: '', icon: '/icons/profile.svg' },
              {
                name: 'Владельцы сайтов',
                description: 'Рекомендуемая тематика: туризм, путешествия, региональные порталы, форумы и т.д.)',
                icon: '/icons/world.svg',
              },
              {
                name: 'Владельцы групп и каналов в социальных сетях',
                description: 'Telegram, VK, Facebook, и т.д.',
                icon: '/icons/comment.svg',
              },
            ],
            possibilities: [
              {
                main: 'Отслеживание статистики',
                description: 'Вы получите возможность анализировать количество заказов, человек.',
                img: '/images/partnership_1.png',
              },
              {
                main: 'Создание партнерских ссылок',
                description: 'Создавайте несколько ссылок под разные цели и направления.',
                img: '/images/partrnership_new.png',
              },
              {
                main: 'Аналитика заказа',
                description: 'Отслеживайте популярные направления, кол-во пассажиров, дату заказа.',
                img: '/images/partrnership_analytics.png',
              },
              {
                main: 'Станьте партнером КупиТрип',
                description: 'Попробуйте все возможности личного кабинета и зарабатывайте прямо сейчас!',
                img: '/images/partnership_4.png',
                accent: true,
              },
            ],
            partnerProgram: 'Партнерская программа',
            earningsFromFlightBookings: 'Зарабатывайте с бронирования авиабилетов до 80₽ за каждого пассажира!',
            becomePartner: 'Стать партнером',
            goToThePartnerProgram: 'Перейти в партнерскую программу',
            howItWorks: 'Как это работает?',
            whoCanBecomePartner: 'Кому пойдет партнерство?',
            opportunities: 'Возможности',
          },
          bookingNeedTicket: {
            statusChanged: 'Статус бронирования изменен',
            bookingWithTicketNumberOffer: 'Если на границе требуют билет с номером, предлагаем вам услугу оформления брони с номером электронного билета. Это выкупленный билет, который проверяется на сайте авиакомпании, имеет код брони и номер билета',
            caution1: '- Бронь необходимо оформлять за 1 день до момента предъявления либо день в день.',
            caution2: '- Срок действия билета - 48 часов.',
            caution3: '- Вы не оплачиваете стоимость билета.',
            pricePerPerson: 'Цена за 1 человека:',
            paymentInRubles: 'При оплате в рублях',
            paymentInEuros: 'При оплате в евро',
            bookWithTicketNumber: 'Оформить бронь с номером билета',
            bookingWithTicketNumber: 'Бронь с номером билета'
          },
          passengerBookingPrice: {
            adultBooking: 'Бронь взрослого',
            childBooking: 'Бронь ребенка',
            babyBooking: 'Бронь младенца',
          },
          bookingTicket: {
            noTicketSelected: 'Не выбран билет для бронирования',
          },
          bookingSidebar: {
            paymentInRubles: 'При оплате в рублях',
            or: 'или',
            paymentInEuros: 'При оплате в евро',
            airlineMayReduceTimeLimit: 'После бронирования авиакомпания может уменьшить тайм-лимит по авиабилетам в одностороннем порядке.',
            validFor7Days: 'Бронь действительна до 7 дней',
            amountDue: 'К оплате',
          },
          orderPaymentSuccess: {
            header: 'Заказ оплачен :)',
            text: 'Спасибо за ваш заказ! В течение 30 минут мы сформируем маршрутную квитанцию и отправим вам отдельным письмом.',
            passengers: 'Пассажиры'
          },
          orderPaymentFail: {
            header: 'Заказ не оплачен :(',
            text: 'Произошла ошибка оплаты вашего заказа, попробуйте оплатить снова.',
            proceedToPayment: 'Перейти к оплате',
            toHomepage: 'На главную'
          },
          blogPosts: {
            title: 'Блог о путешествиях',
            all: 'Все',
          },
          badGateway: {
            title: 'Упс.. ведутся технические работы, попробуйте вернуться через пару минут',
            error: 'Ошибка 502'
          },
          flightArrow: {
            transfer: 'Пересадка',
          },
          suggestion: {
            noMatches: 'Нет совпадений',
          },
          orderStatus: {
            paymentFailed: 'Оплата не прошла',
            payed: 'Оплачено',
            waiting: 'Ожидает оплаты',
            expired: 'Просрочен',
          },
          orderPrice: {
            total: 'Сумма заказа',
          },
          orderPassengers: {
            numberOfPassengers: 'Кол-во пассажиров',
          },
          orderNumber: {
            orderNumber: 'Номер заказа'
          },
          orderDocument: {
            orderDocument: 'Документы бронирования',
            helpMessage: 'Документы с бронированием будут доступны для скачивание через 30 минут',
            download: 'Скачать'
          },
          orderDetails: {
            notUploaded: 'Билет не загружен',
            pay: 'Оплатить',
          },
          orderDate: {
            orderDate: 'Дата заказа',
          },
          orderCard: {
            more: 'Подробнее',
            pay: 'Оплатить',
          },
          previewOrder: {
            process: 'Оформить',
            cancel: 'Отменить',
            noOrderError: 'Не выбран заказ для просмотра'
          },
          bookingComment: {
            bookingComment: 'Комментарий к заказу',
          },
          help: {
            header: 'Помощь',
          },
          notFound: {
            title: "Страница не найдена :(",
            subtitle: "Попробуйте обновить страницу или вернуться на главную",
            buttonText: "На главную",
          },
          flightRoute: {
            routes: 'Рейсы',
            route: 'Рейс',
            days: 'д',
            hours: 'ч',
            minutes: 'мин',
          },
          passengers: {

          },
          passwordData: {
            oldPassword: 'Старый пароль',
            passwordsDoNotMatch: 'Пароль не совпадает',
            newPassword: 'Новый пароль',
            confirmPassword: 'Повтор пароля',
            changePassword: 'Изменить пароль',
          },
          contactsData: {
            phone: 'Телефон',
            contactDetails: 'Контактные данные',
            loggedInWithGoogle: 'Вы вошли через Google',
          },
          formSubmit: {
            personalDataSaved: 'Персональные данные успешно сохранены'
          },
          personalForm: {
            alreadyTaken: 'Указанный адрес электронной почты принадлежит другому пользователю',
            save: 'Сохранить',
            failedToUpdatePasswordCheckOldPassword: 'Не удалось обновить пароль, проверьте старый пароль',
            passwordNotUpdatedCheckOldValidity: 'Пароль не обновлен, проверьте валидность старого!',
          },
          citizenship: {
            Russia: 'Россия',
            Belarus: 'Беларусь',
            Ukraine: 'Украина',
            Kazakhstan: 'Казахстан',
          },
          personalCitizenship: {
            popular: 'Популярное',
            allCountries: 'Все страны',
            noResults: 'Нет совпадений',
          },
          passengerType: {
            passengerType: 'Тип пассажира',
            adult: {
              label: 'Взрослый',
              description: 'От 12 лет',
            },
            child: {
              label: 'Ребенок',
              description: 'От 2 до 12 лет',
            },
            baby: {
              label: 'Младенец',
              description: 'До 2 лет, без места',
            },
          },
          passengerHeader:{
            passenger: 'пассажир',
            howToFillData: 'Как заполнить данные'
          },
          passengerForm: {
            add: 'Добавить',
            save: 'Сохранить',
            cancel: 'Отмена',
            delete: 'Удалить',
            added: 'Пассажир успешно создан',
            updated: 'Пассажир успешно обновлен',
          },
          passengerCard: {
            gender: 'Пол',
            male: 'Мужской',
            female: 'Женский',
            birth: 'День рождения',
            citizenship: 'Гражданство',
            passportId: 'Номер документа',
            edit: 'Редактировать',
            editPassenger: 'Редактировать пассажира',
            save: 'Сохранить'
          },
          placementInput: {
            enterLink: 'Введите корректную ссылку',
            required: 'Поле обязательно'
          },
          placementLinks: {
            successMessage: 'Места размещения успешно сохранены',
            errorMessage: 'Что-то пошло не так',
            maxEntries: 'Нельзя добавить больше 5 записей',
            placement: 'Место размещения',
            link: 'Ссылка',
            save: 'Сохранить',
            placements: 'Места размещения',
            addPlacement: 'Добавить место размещения',
            delete: 'Удалить',
          },
          register: {
            register: 'Регистрация'
          },
          contactDetails: {
            contactDetails: 'Контактные данные',
            contactDetailsDescription: 'На почту мы отправим документ “Подтверждение бронирования”',
            required: 'Поле обязательно',
            phone: 'Телефон'
          },
          securityAndReturnPolicy: {
            securityAndReturnPolicy: 'Политика безопасности и возврата',
            paymentSecurityPolicy: 'Политика безопасности платежей',
            paymentOptions: 'Оплатить заказ можно:',
            paymentOption1: '1. С помощью банковских карт международных платёжных систем Visa, MasterCard, МИР.',
            paymentOption2: '2. Через Систему Быстрых Платежей.',
            paymentOption3: '3. Yandex Pay.',
            paymentSecurityDescription: 'Приём платежей происходит через защищённое безопасное соединение, используя протокол TLS 1.2. Компания\n' +
              '          Best2pay соответствует международным требованиями PCI DSS для обеспечения безопасной обработки реквизитов\n' +
              '          банковской карты плательщика. Ваши конфиденциальные данные необходимые для оплаты (реквизиты карты,\n' +
              '          регистрационные данные и др.) не поступают в интернет-портал, их обработка производится на стороне\n' +
              '          процессингового центра Best2pay и полностью защищена. Никто, в том числе интернет-портал КУПИТРИП, не может\n' +
              '          получить банковские и персональные данные плательщика.',
            refundPolicy: 'Правила возврата',
            refundPolicyDescription: 'При отказе от услуги, оплаченная сумма за услугу, подлежит возврату в течение\n' +
              '          10 дней (ст 22 Закона РФ «О защите прав потребителей» N 2300-1 от 07.02- далее Закон № 2300-1).',
            attentionNotice: 'Обратите внимание!',
            serviceCancellationPolicy: 'Отказ от услуги возможен только до бронирования авиабилетов, отправленных на\n' +
              '          электронную почту. Возврат осуществляется на ту же банковскую карту, с которой была оплачена услуга.',
          },
          language: {
            header: 'Язык и валюта',
            language: 'Язык',
            currency: 'Валюта',
            russian: 'Русский',
            english: 'English',
            rubles: 'Российский рубль (RUB)',
            euros: 'Евро (EUR)',
            confirm: 'Подтвердить',
          },
          validation: {
            required: 'Поле обязательно',
            invalidDocumentNumber: 'Неверный номер документа',
            maxLength: 'Максимальная длина 255 знаков',
            minLength: 'Минимальная длина пароля 8 символов',
            useLatinLettersHyphenSpaceOnly: 'Используйте только латинские буквы, дефис или пробел',
            useLettersDotHyphenSpaceOnly: 'Используйте только буквы, точку, дефис или пробел',
            invalidPassportNumber: 'Неверный номер паспорта',
            invalidEmailFormat: 'Некорректный формат электронной почты',
            selectOneFromList: 'Выберите один пункт из списка',
            invalidBirthCertificateNumber: 'Неверный номер свидетельства о рождении',
            passwordMustContainLatinLettersNumbersAndSymbols: 'Пароль может состоять лишь из латинских букв разного регистра, цифр и символов',
            invalidDate: 'Неверная дата',
            invalidDateFormat: 'Неверный формат даты (используйте: дд.мм.гггг)',
            dateInFutureNotAllowed: 'Дата в будущем недопустима',
            documentExpiryDateMustBeAfterArrivalDate: 'Дата срока окончания документа должна быть больше даты прилета',
            invalidBirthYear: 'Неверный год рождения',
            dateCannotBeGreaterThan: 'Не может быть больше',
            dateCannotBeLessThan: 'Не может быть меньше',
            andCannotBeGreaterThan: 'и больше',
            dateInThePastNotAllowed: 'Дата в прошлом недопустима',
            onlyLatinCharactersAllowed: 'Допустимы только латинские символы',
            invalidPhoneNumberFormat: 'Некорректный формат номера телефона',
            stringMustStartWithLatinLetter: 'Строка должна начинаться с латинской буквы',
            stringCannotConsistOfSpaces: 'Строка не должна состоять из пробелов',
            cardNumberMustContainAtLeast16Characters: 'Номер карты содержит минимум 16 символов'
          },
          partnershipLandingPage: {
            partnershipProgramTitle: 'Партнерская программа КупиТрип',
            partnershipProgramSubtitle: 'Зарабатывайте с бронирования авиабилетов до {{amount}}₽ за каждого пассажира!',
            becomePartnerButton: 'Стать партнером',
            howItWorksTitle: 'Как это работает?',
            whoCanPartnerTitle: 'Кому пойдет партнерство?',
            opportunitiesTitle: 'Возможности'
          },
          rules: {
            offerAgreementTitle: 'Договор оферты',
            publicOfferTitle: 'Публичная оферта о заключении договора на оказание услуг бронирования авиабилетов и прочих услуг',
            offerText: 'Настоящая Оферта, является официальным публичным предложением «Купитрип» ИП Воронин А.Н ОГРН 322237500003197 (далее Агентство) заключить Договор между Клиентом и Агентством на оказание услуг бронирования авиабилетов и прочих услуг далее Договор) на Веб-сайте Агентства www.kupitrip.online. Договор считается заключенным и приобретает силу с момента совершения физическим лицом действий, предусмотренных офертой и означающих безоговорочное принятие всех условий оферты без каких-либо изъятий или ограничений, на условиях присоединения.',
            termsDefinitionsTitle: '1. Термины и определения',
            termsDefinitionsIntro: '1.1 В рамках настоящей Оферты нижеприведенные термины используются в следующем значении:',
            termOffer: '1.1.1 Оферта – адресованное любому физическому лицу предложение Агентства на заключение Договора на условиях, содержащихся в настоящей Оферте.',
            termPublication: '1.1.2 Опубликование – размещение текста настоящей Оферты в свободном доступе в сети Интернет по адресу: www.kupitrip.online/offerta',
            termAgency: '1.1.3 Агентство – «Купитрип» ИП Воронин А.Н ОГРН 322237500003197',
            termAgencyNotCarrier: '1.1.4 Агентство не является авиаперевозчиком, услуги Агентства носят посреднический характер. Агентство обязуется оказать Клиенту услуги по бронированию авиабилетов на регулярные рейсы перевозчиков и прочих услуг поставщиков, а Заказчик обязуется оплатить услуги Агентства по установленной стоимости в разделе Тарифы.',
            termClient: '1.1.5 Клиент (пользователь) – физическое, совершающее онлайн-бронирование на интернет-портале Агентства: www.kupitrip.online',
            termCarrier: '1.1.6 Перевозчик – авиакомпания эксплуатант, осуществляющий воздушные перевозки пассажиров, багажа, имеющий лицензию на осуществление подлежащего лицензированию в соответствии с законодательством Российской Федерации вида деятельности в области авиации, обеспечивающий услуги по перевозке пассажиров и багажа в пункт временного/постоянного пребывания.',
            termPassenger: '1.1.7 Пассажир – физическое лицо, заключившее договор воздушной перевозки пассажира, либо физическое лицо, в целях перевозки которого заключен договор фрахтования воздушного судна (воздушный чартер).',
            termContract: '1.1.8 Договор - заключаемый посредством акцепта оферты договор между Клиентом и Агентством на указанных ниже условиях, является официальным предложением (публичной офертой) и содержит все существенные условия по оказанию услуг на Веб-сайте Агентства www.kupitrip.online',
            termOrder: '1.1.9 Заказ – должным образом, оформленный на веб-сайте запрос Клиента на бронирование авиабилетов или прочих услуг, включающий сведения о маршруте перевозки, датах вылета/прилета, классе обслуживания, фамилии, имени, отчества клиента/ов, данные документов, удостоверяющих личность пассажиров, контакты. В случае оформления международной перевозки, имя и фамилия пассажира в латинской транскрипции должны точно соответствовать их написанию в загранпаспорте. Маршрут-квитанция электронного билета - часть электронного билета, содержащая установленные законодательством Российской Федерации сведения о перевозке пассажира и является документом, удостоверяющим заключение договора воздушной перевозки пассажира между авиакомпанией-перевозчиком и пассажиром.',
            termTimeLimit: '1.1.10 Тайм-лимит - срок выкупа авиабилета или отеля, устанавливается согласно правилам и распоряжениям Перевозчиков или Отеля. Перевозчики или отель оставляют за собой право на изменение тайм-лимита, аннуляции бронирования в любое время.',
            termClientAction: '1.1.11 Совершение Клиентом действий по заказу электронных документов, посредством интернет-ресурса www.kupitrip.online, электронной почты, свидетельствует о принятии Вами всех условий настоящего Договора-оферты. В соответствии с условиями использования нашего Сайта Вы подтверждаете, что Вам исполнилось 18 лет, Вы дееспособны, правоспособны, имеете законное право пользоваться банковской платежной картой и вступать в договорные отношения с Агентством. Вы берете на себя ответственность по обязательствам, что возникают в результате исполнения настоящего договора. Если Клиент не согласен с этими правилами и условиями, он не имеет права использовать настоящий интернет-портал в целях использования размещенной на нем системы бронирования.',
            contractSubjectTitle: '2. Предмет договора',
            contractSubjectAgencyObligation: '2.1 Агентство обязуется оказать Клиенту посреднические услуги по бронированию авиабилетов и прочих услуг.',
            contractSubjectAgencyObligationDependent: '2.2 Обязательство Агентства по обеспечению услуги бронирования, предусмотренное настоящим Договором, во всех случаях зависит от фактического наличия мест у Поставщиков.',
            contractSubjectAgencyObligationCompleted: '2.3 С момента получения Клиентом оформленных документов по электронной почте, обязательства Агентства перед Клиентом считаются исполненными, а услуга - оказанной. В случае отказа Клиентом от оказанной услуги, оплаченные денежные средства не подлежат возврату.',
            contractSubjectAgencyResponsibility: '2.4 Агентство не несет ответственность за решение консульством/посольством о выдачи визы или гарантированного прохождения границы в стране вылета/прилета.',
            bookingPaymentOrderTitle: '3. Порядок бронирования и оплаты',
            bookingPaymentOrderAgencyObligation: '3.1 Обязательства Агентства по бронированию распространяются на подтвержденные и не отмененные Клиентом Заказы, содержащие информацию, доводимую Клиентом до Агентства на интернет-портале www.kupitrip.oline путем самостоятельного заполнения формы Заказа. Заказ Клиента должен содержать следующие сведения: маршрут перевозки, даты вылета/прилета, класс обслуживания, фамилию, имя, отчество, пол клиентов/пассажиров (в случае оформления международной перевозки, имя и фамилия пассажира в латинской транскрипции должны точно соответствовать их написанию в загранпаспорте), данные документов, удостоверяющих личность пассажиров, контакты для обратной связи с Клиентом: телефон, электронная почта или иное средство оперативной связи.',
            bookingPaymentOrderClientAcceptance: '3.2 Заполнение формы Заказа онлайн является акцептом настоящего Договора со стороны Клиента. Заказ является указанием Клиента для выполнения поручения и содержит в себе информацию, указанную в п. 3.1. настоящего Договора.',
            bookingPaymentOrderConfirmation: '3.3 После получения оплаты, Агентство высылает на адрес электронной почты Клиента оформленные документы.Бронирование билета оформляется на основании данных документа, удостоверяющего личность пассажира в соответствии с законодательством Российской Федерации или международным договором Российской Федерации для перевозки пассажира по маршруту, предусмотренному договором воздушной перевозки пассажира. Агентство считается исполнившим свои обязательства в момент отправки на электронную почту Клиента маршрутной квитанции.',
            agencyObligationsTitle: '4. Обязанности Агентства',
            agencyObligationsBooking: '4.1 Агентство обязуется забронировать авиабилет или прочую услугу согласно подтвержденному Заказу Клиента (за исключением случаев отмены Заказа).',
            clientObligationsTitle: '5. Обязанности Клиента',
            clientObligationsPayment: '5.1 Произвести своевременную оплату и предоставить достоверные и точные сведения, необходимые для бронирования авиабилетов или прочих услуг, указанные в пункте 3.1.',
            clientObligationsContactInfo: '5.2 Предоставить Агентству точную информацию о своем электронном адресе и телефоне, необходимую Агентству для оперативной связи с Клиентом.',
            clientObligationsInform: '5.3 Ознакомить всех пассажиров (в случае оформления заказа в интересах третьих лиц) с содержанием настоящего Договора и со всей информацией, предоставленной Агентством Клиенту, обеспечить выполнение ими всех обязанностей Клиента по Договору. Клиент гарантирует наличие у себя полномочий на осуществление сделки в интересах иных лиц.',
            offerValidityTitle: '6. Срок действия и изменение условий оферты',
            offerValidityEffective: '6.1 Оферта вступает в силу с момента опубликования на сайте Агентства в сети интернет, по адресу www.kupitrip.online и действует до момента отзыва Оферты Агентством.',
            offerValidityChanges: '6.2 Агентство оставляет за собой право внести изменения в условия Оферты и/или отозвать Оферту в любой момент по своему усмотрению. В случае внесения Агентством изменений в Оферту, такие изменения вступают в силу с момента опубликования на Сайте, если иной срок вступления изменений в силу не определен дополнительно при их публикации.',
            contractDurationTitle: '7. Срок действия, изменение и расторжение договора',
            contractDurationEffective: '7.1 Договор вступает в силу с момента Акцепта Оферты и действует до момента исполнения Агентством обязательств по бронированию услуги и отправления документов на электронную почту Клиента.',
            contractDurationTermination: '7.2 Настоящий Договор может быть расторгнут по взаимному согласию Сторон или по иным основаниям, предусмотренным действующим законодательством РФ',
            contractDurationCancellation: '7.3 Отказ от услуги возможен только до бронирования авиабилетов, отправленных на электронную почту.',
            otherConditionsTitle: '8. Прочие условия',
            otherConditionsGoverningLaw: '8.1 Все вопросы, не урегулированные Офертой или Договором, регламентируются действующим законодательством Российской Федерации. Споры и разногласия, которые могут возникнуть при исполнении настоящего Договора, будут по возможности разрешаться путем переговоров между сторонами Договора и в претензионном порядке. Претензионный порядок рассмотрения спора является обязательным. Срок ответа на претензию устанавливается в соответствии с действующим законодательством. В случае не урегулирования разногласий спор подлежит рассмотрению в суде с применением права РФ.',
            otherConditionsIntegrity: '8.2 Целостность Договора. Агентство не принимает на себя никаких условий и обязательств в отношении предмета Оферты (Заказа), не указанных в Оферте. Исключение могут составлять случаи, когда такие условия или обязательства зафиксированы в письменном виде и подписаны Агентством и Заказчиком.',
            otherConditionsInvalidity: '8.3 Последствия признания условий оферты недействительными. Если какое-либо из условий Оферты признано судом недействительным или является ничтожным, данный факт не влияет на недействительность Договора.',
            otherConditionsPersonalData: 'Клиенту разъяснены и понятны права субъекта персональных данных. ' +
              'Внимательно ознакомьтесь с текстом публичной оферты, и если Вы не согласны с каким-либо ' +
              'пунктом оферты, Вы вправе отказаться от услуг Агентства и не совершать действий, указанных в настоящей ' +
              'Оферте.',
          },
          personalData: {
            personalData: 'Персональные данные',
            surnameLabel: 'Фамилия (на латинице)',
            nameLabel: 'Имя (на латинице)',
            patronymicLabel: 'Отчество',
            noPatronymicLabel: 'Нет отчества',
            birthDateLabel: 'Дата рождения',
            genderLabel: 'Пол',
            male: 'Мужской',
            female: 'Женский',
          },
          bookingHeader: {
            backToSearch: 'Вернуться к поиску',
          },
          flightTag: {
            direct: 'Прямой',
          },
          flightTransfer: {
            transfer: 'Пересадка',
          },
          scrollStories: {
            moreDetails: 'Подробнее',
          },
          rangeDatePicker: {
            oneWayOnly: 'Только в одну сторону',
          },
          partnershipLinks: {
            title: 'Партнерские ссылки',
            newLinkButton: 'Новая ссылка',
            minPayment: 'Минимальная сумма выплат по партнерской программе составляет 1000 рублей. По условиям партнерской программы - за каждого оформленного пассажира в заказе - вы получаете до {{amount}} рублей.',
            passengers: 'Пассажиры',
            orders: 'Заказы',
            income: 'Доход',
            expectedPayout: 'Ожидаемая сумма выплаты в конце месяца',
            loading: 'Идет загрузка...',
            noLinksTitle: 'Нет созданных ссылок',
            noLinksSubtitle: 'Создайте первую ссылку что бы начать пользоваться партнерской программой',
            sortOptions: {
              allTime: 'Все время',
              today: 'Сегодня',
              sevenDays: '7 дней',
              thirtyDays: '30 дней'
            }
          },
          partnershipLinkDetails: {
            linkCopied: 'Ссылка успешно скопирована в буфер обмена',
            loading: 'Идет загрузка...',
            noLinkData: 'Нет данных по ссылке',
            copyLink: 'Скопировать ссылку',
            deleteLink: 'Удалить ссылку',
            passengers: 'Пассажиры',
            orders: 'Заказы',
            income: 'Доход',
            noCurrentOrdersTitle: 'Нет текущих заказов',
            noCurrentOrdersSubtitle: 'За данный промежуток времени по ссылке не оформлены заказы'
          },
          ordersLinksTable: {
            sort: 'Сортировать',
            passengers: 'Пассажиры',
            date: 'Дата оформления',
            income: 'Доход',
            apply: 'Применить',
            descending: 'По убыванию',
            ascending: 'По возрастанию',
            direction: 'Направление',
          },
          linksTable: {
            sort: 'Сортировать',
            descending: 'По убыванию',
            ascending: 'По возрастанию',
            apply: 'Применить',
            orders: 'Заказы',
            passengers: 'Пассажиры',
            income: 'Доход',

          },
          stepCard: {
            step: 'шаг'
          },
          personalDocument: {
            passportData: 'Данные паспорта',
            citizenship: 'Гражданство',
            document: 'Документ',
            passportNumber: 'Номер документа',
            validity: 'Срок действия',
            hasValidity: 'Срок действия',
            foreignPassportRF: 'Заграничный паспорт РФ',
            foreignPassport: 'Иностранный документ',
            internalPassportRF: 'Внутренний паспорт РФ',
          },
          profileMenu: {
            partnerUser: 'Пользователь-партнер',
            personalData: 'Персональные данные',
            orders: 'Заказы',
            passengers: 'Пассажиры',
            partnershipProgram: 'Партнерская программа',
            logout: 'Выйти'
          },
          confidentialPolicy: {
            processingPersonalData: "Обработка персональных данных",
            generalProvisions: "1. Общие положения",
            policyText1: "Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных 'Купитрип' ИП Воронин А.Н ОГРН 322237500003197 (далее – Оператор).",
            policyText2: "Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну. Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта https://kupitrip.online.",
            termsDefinitions: "2. Основные понятия, используемые в Политике",
            automatedProcessing: "Автоматизированная обработка персональных данных",
            automatedProcessingDescription: 'обработка персональных данных с помощью средств вычислительной техники',
            blockingData: 'Блокирование персональных данных',
            blockingDataDescription: 'временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных)',
            website: 'Веб-сайт',
            websiteDescription: 'совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу https://kupitrip.online',
            infoSystem: 'Информационная система персональных данных',
            infoSystemDescription: 'совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств',
            dataAnonymization: 'Обезличивание персональных данных',
            dataAnonymizationDescription: 'действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных',
            dataProcessing: 'Обработка персональных данных',
            dataProcessingDescription: 'любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных',
            operator: 'Оператор',
            operatorDescription: 'государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными',
            personalData: 'Персональные данные',
            personalDataDescription: 'любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта https://kupitrip.online',
            user: 'Пользователь',
            userDescription: 'любой посетитель веб-сайта https://kupitrip.online',
            dataProvision: 'Предоставление персональных данных',
            dataProvisionDescription: 'действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц',
            dataDistribution: 'Распространение персональных данных',
            dataDistributionDescription: 'любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом',
            crossBorderTransfer: 'Трансграничная передача персональных данных',
            crossBorderTransferDescription: 'передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу',
            dataDestruction: 'Уничтожение персональных данных',
            dataDestructionDescription: 'любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) результате которых уничтожаются материальные носители персональных данных',
            dataProcessingPurpose: '3. Оператор может обрабатывать следующие персональные данные Пользователя',
            dataProcessingPurposeText1: 'Фамилия, имя, отчество',
            dataProcessingPurposeText2: 'Электронный адрес',
            dataProcessingPurposeText3: 'Номера телефонов',
            dataProcessingPurposeText4: 'Год, месяц, дата и место рождения',
            dataProcessingPurposeText5: 'Реквизиты документа, удостоверяющего личность',
            dataProcessingPurposeText6: 'Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и других)',
            dataProcessingPurposeText7: 'Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные',
            dataProcessingPurposeText8: 'Цель обработки персональных данных Пользователя — информирование Пользователя посредством отправки электронных писем',
            dataProcessingPurposeText9: 'Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях',
            dataProcessingPurposeText10: 'Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты info@kupitrip.online с пометкой «Отказ от уведомлениях о новых продуктах и услугах и специальных предложениях»',
            dataProcessingPurposeText11: 'Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания',
            legalBasis: '5. Правовые основания обработки персональных данных',
            legalBasisText1: 'Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на сайте https://kupitrip.online.',
            legalBasisText2: 'Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.',
            legalBasisText3: 'Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).',
            processingOrder: '6. Порядок сбора, хранения, передачи и других видов обработки персональных данных',
            processingOrderText1: 'Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.',
            processingOrderText2: 'Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.',
            processingOrderText3: 'Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства.',
            processingOrderText4: 'В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора info@kupitrip.online с пометкой «Актуализация персональных данных».',
            processingOrderText5: 'Срок обработки персональных данных является неограниченным.',
            processingOrderText6: 'Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора info@kupitrip.online с пометкой «Отзыв согласия на обработку персональных данных».',
            crossBorderTransferText1: 'Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных.',
            crossBorderTransferText2: 'Трансграничная передача персональных данных на территории иностранных государств, не отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, стороной которого является субъект персональных данных.',
            finalProvisions: '8. Заключительные положения',
            finalProvisionsText1: 'Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты info@kupitrip.online.',
            finalProvisionsText2: 'В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.',
            finalProvisionsText3: 'Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу https://kupitrip.online/confedential_policy',
          },
          searchTypeEnum: {
            eco: 'Эконом',
            biz: 'Бизнес',
          },
          passengersDropdown: {
            adults: 'Взрослые',
            adultsDescription: '12 лет и старше',
            children: 'Дети',
            childrenDescription: 'От 2 до 12 лет',
            babies: 'Младенцы',
            babiesDescription: 'До 2 лет, без места'
          },
          loginCard: {
            signIn: 'Авторизуйтесь',
            loginToAccount: 'Вход в личный кабинет',
            buttonLogin: 'Вход',
          },
          partnershipLinksTable: {
            name: 'Название/ссылка',
            ordersTotal: 'Заказы',
            passengersTotal: 'Пассажиры',
            incomeTotal: 'Доход',
          },
          passengerTypes: {
            adult: 'взрослый',
            child: 'ребенок',
            baby: 'младенец'
          },
          images: {
            price: '/images/3png@3x2.png',
            search: '/images/1png@3x.png',
            passengerInfo: '/images/2png@3x.png',
            pdf: '/images/1png1@2x.png',
            telegram: '/images/1png3@3x.png',
          },
          titles: {
            bookingAirlineTicketsForVisa: 'Бронирование авиабилетов для визы',
            fareAndPriceForKupiTripBooking: 'Тариф и цена на бронирование купитрип/ купитрип бронь/',
            howMuchDoKupiTripServicesCost: 'Сколько стоят услуги КупиТрип?',
            kupiTripBookingReviews: 'КупиТрип бронирование, отзывы',
          },
          app: {
            createPassword: {
              header: 'Создать пароль',
              text: 'Регистрация почти завершена, осталось создать пароль для аккаунта',
              button: 'Создать'
            },
            changePassword: {
              header: 'Сменить пароль',
              text: 'Введите новый пароль',
              button: 'Сохранить'
            },
            invalidLink: {
              header: 'Неверная ссылка',
              text: 'Некорректная ссылка. Получите ссылку заново'
            },
          },
          metaDescriptions: {
            bookingAirlineTicketsForVisa: 'Бронирование Авиабилетов для визы без оплаты билета. Бронь билета без оплаты за 1190 рублей. Официальная бронь. Онлайн бронирование билетов для визы',
            fareAndPriceForKupiTripBooking: 'Тариф и цена на бронирование купитрип/ купитрип бронь/',
            kupiTripBookingReviews: 'Отзывы о сервисе купитрип. Бронирование авиабилетов купитрип отзывы.',
            frequentlyAskedQuestionsKupiTrip: 'Популярные вопросы к сервису купитрип. Сколько стоят услуги купитрип. Можно ли вернуть бронь купитрип? Официальная бронь.'
          }
        },
      },
    },
  });

export default i18n;
