import { FunctionComponent } from 'react';
import clsx from 'clsx';
import styles from './InfoMessage.module.scss';

type InfoMessageType = {
  title?: string;
  subtitle?: string;
  className?: string;
};

const InfoMessage: FunctionComponent<InfoMessageType> = ({ title, subtitle, className = '' }) => {
  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
    </div>
  );
};

export default InfoMessage;
