import clsx from 'clsx';
import Card from '../../../Card/Card';
import styles from './TotalCard.module.scss';

interface Props {
  icon: string;
  name: string;
  value?: number | string;
  className?: string;
}

const TotalCard = ({ icon, name, value, className }: Props) => {
  return (
    <Card className={clsx(styles.root, className)}>
      <div className={styles.row}>
        <img className={styles.img} src={icon} />
        <span className={styles.main}>{name}</span>
      </div>
      <span className={styles.value}>{value}</span>
    </Card>
  );
};
export default TotalCard;
