import clsx from 'clsx';
import { MouseEventHandler, useState } from 'react';
import styles from './Pagination.module.scss';

interface Props {
  className?: string;
  currentPage?: number;
  lastPage?: number;
  changePage?: (page: number) => void;
}

const getPages = (currentPage: number = 1, lastPage: number = 5) => {
  const MAX_BUTTONS = 5;
  const MIN_INDEX = Math.max(1, currentPage - 2);
  const MAX_INDEX = Math.min(MIN_INDEX + MAX_BUTTONS - 1, lastPage);
  const arraySize = MAX_INDEX - MIN_INDEX + 1;
  return new Array(arraySize).fill(0).map((_, i) => ({ name: i + MIN_INDEX }));
};

const Pagination = ({ className, currentPage, lastPage, changePage }: Props) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = e => {
    if (!e.currentTarget) return;
    const { name } = e.currentTarget;
    changePage && changePage(Number(name));
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const getClassName = (name: number) => {
    return name === currentPage ? clsx(styles.page, styles.active) : styles.page;
  };
  const pages = getPages(currentPage, lastPage);

  return (
    <div className={clsx(styles.pagination, className)}>
      {pages.map(page => {
        const className = getClassName(page.name);
        return (
          <button key={page.name} type="button" name={page.name.toString()} onClick={handleClick} className={className}>
            {page.name}
          </button>
        );
      })}
    </div>
  );
};
export default Pagination;
