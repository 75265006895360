import { FC, ReactNode } from 'react';
import clsx from 'clsx';
import styles from './Card.module.scss';

interface Props {
  children: ReactNode | ReactNode[];
  className?: string;
}

const Card: FC<Props> = ({ children, className }) => {
  return <div className={clsx(styles.root, className)}>{children}</div>;
};

export default Card;
