import { lazy, Suspense } from 'react';
import { retry } from '../../utils/componentLoader';
import { useSelector } from 'react-redux';
import { modalSliceSelector } from '../../store/slices/modal';

const CreatePassword = lazy(() => retry(() => import('./CreatePassword/CreatePassword')));
const DeletePassenger = lazy(() => retry(() => import('./DeletePassenger/DeletePassenger')));
const FeedbackView = lazy(() => retry(() => import('./FeedbackView/FeedbackView')));
const FeedbackCreate = lazy(() => retry(() => import('./FeedbackCreate/FeedBackCreate')));
const Success = lazy(() => retry(() => import('./Success/Success')));
const ForgotPassword = lazy(() => retry(() => import('./ForgotPassword/ForgotPassword')));
const Login = lazy(() => retry(() => import('./Login/Login')));
const Language = lazy(() => retry(() => import('./Language/Language')));
const Register = lazy(() => retry(() => import('./Register/Register')));
const FormInfo = lazy(() => retry(() => import('./FormInfo/FormInfo')));
const PreviewOrder = lazy(() => retry(() => import('./PreviewOrder/PreviewOrder')));
const BecomePartner = lazy(() => retry(() => import('./BecomePartner/BecomePartner')));
const CreatePartnerLink = lazy(() => retry(() => import('./CreatePartnerLink/CreatePartnerLink')));
const DeleteLinkModal = lazy(() => retry(() => import('./DeleteLink/DeleteLink')));
const DeletePassengerModal = lazy(() => retry(() => import('./DeletePassenger/DeletePassenger')));
const PaymentModal = lazy(() => retry(() => import('./PaymentModal/PaymentModal')));
const UpdatePassenger = lazy(() => retry(() => import('./UpdatePassenger/UpdatePassenger')));

const Modals = () => {
  const {
    becomePartner,
    createPartnerLink,
    deletePassenger,
    deleteLink,
    login,
    language,
    register,
    success,
    createPassword,
    forgotPassword,
    formInfo,
    previewOrder,
    feedbackView,
    feedbackCreate,
    payment
  } = useSelector(modalSliceSelector);
  return (
    <>
      {becomePartner ? (
        <Suspense fallback={null}>
          <BecomePartner />
        </Suspense>
      ) : null}
      {createPartnerLink ? (
        <Suspense fallback={null}>
          <CreatePartnerLink />
        </Suspense>
      ) : null}
      {deletePassenger ? (
        <Suspense fallback={null}>
          <DeletePassengerModal />
        </Suspense>
      ) : null}
      {deleteLink ? (
        <Suspense fallback={null}>
          <DeleteLinkModal />
        </Suspense>
      ) : null}
      {feedbackView ? (
        <Suspense fallback={null}>
          <FeedbackView />
        </Suspense>
      ) : null}
      {feedbackCreate ? (
        <Suspense fallback={null}>
          <FeedbackCreate />
        </Suspense>
      ) : null}
      {login ? (
        <Suspense fallback={null}>
          <Login />
        </Suspense>
      ) : null}
      {language ? (
        <Suspense fallback={null}>
          <Language />
        </Suspense>
      ) : null}
      {register ? (
        <Suspense fallback={null}>
          <Register />
        </Suspense>
      ) : null}
      {success ? (
        <Suspense fallback={null}>
          <Success />
        </Suspense>
      ) : null}
      {createPassword ? (
        <Suspense fallback={null}>
          <CreatePassword />
        </Suspense>
      ) : null}
      {forgotPassword ? (
        <Suspense fallback={null}>
          <ForgotPassword />
        </Suspense>
      ) : null}
      {formInfo ? (
        <Suspense fallback={null}>
          <FormInfo />
        </Suspense>
      ) : null}
      {previewOrder ? (
        <Suspense fallback={null}>
          <PreviewOrder />
        </Suspense>
      ) : null}
      {payment ? (
        <Suspense fallback={null}>
          <PaymentModal />
        </Suspense>
      ) : null}
      {UpdatePassenger ? (
        <Suspense fallback={null}>
          <UpdatePassenger />
        </Suspense>
      ) : null}
    </>
  );
};

export default Modals;
