import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Airport, City, TicketType } from '../../types/ticket';
import { ISearchResult } from '../api/searchService';
import { RootState } from '../store';
import {IAirlines} from "../../types/backend";
import { t } from 'i18next';

interface ISearchSlice {
  from: Airport | City | null;
  to: Airport | City | null;
  search_airlines: IAirlines[] | {};
  search_airports: Airport[] | {};
  filter_airlines: string[];
  adults: number;
  children: number;
  babies: number;
  type: TicketType;
  data?: ISearchResult;
  price_from?: number;
  price_to?: number;
  transfers_count: number;
  transfers_more_than: boolean;
  transfers_rules: string[];
  transfer_duration_from?: number;
  transfer_duration_to?: number;
  airlines: [];
  time_trip_from_min: number;
  time_trip_from_max: number;
  time_trip_to_min: number;
  time_trip_to_max: number;
  time_back_from_min: number;
  time_back_from_max: number;
  time_back_to_min: number;
  time_back_to_max: number;
  isLoading: boolean;
}

const initialState: ISearchSlice = {
  from: null,
  to: null,
  adults: 1,
  children: 0,
  babies: 0,
  type: 'eco',//'any',
  data: {},
  search_airlines: {},
  search_airports: {},
  filter_airlines: [],
  airlines: [],
  transfers_count: 0,
  transfers_more_than: true,
  transfers_rules: [],
  time_trip_from_min: 0,
  time_trip_from_max: 1440,
  time_trip_to_min: 0,
  time_trip_to_max: 1440,
  time_back_from_min: 0,
  time_back_from_max: 1440,
  time_back_to_min: 0,
  time_back_to_max: 1440,
  isLoading: false
};
export type ISetSearchPayload = { from?: Airport | City | null; to?: Airport | City | null };
export type ISetTypePayload = { type?: TicketType; };
export type ISetPassengersPayload = { adults?: number; children?: number; babies?: number };
export type ISetBackendDataPayload = { data: ISearchResult };
export type ISetFilterBackendDataPayload = { key: string | undefined; value: any; };

export const SearchTypeEnum = {
  // 'any': 'Любой',
  'eco': t('searchTypeEnum.eco'),
  'biz': t('searchTypeEnum.biz'),
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setLoading: (state, { payload: { value } }: PayloadAction<{ value: boolean }>) => {
      state.isLoading = value;
    },
    setSearch: (state, { payload: { from, to } }: PayloadAction<ISetSearchPayload>) => {
      if (from || from === null) state.from = from;
      if (to || to === null) state.to = to;
    },
    setType: (state, { payload: { type } }: PayloadAction<ISetTypePayload>) => {
      if (type) state.type = type;
    },
    swapRoutes: (state, { }: PayloadAction) => {
      const copy = JSON.parse(JSON.stringify(state.from));
      state.from = state.to;
      state.to = copy;
    },
    setPassengers: (state, { payload: { adults, children, babies } }: PayloadAction<ISetPassengersPayload>) => {
      if (adults !== undefined) state.adults = adults;
      if (children !== undefined) state.children = children;
      if (babies !== undefined) state.babies = babies;
    },
    saveBackendData: (state, { payload: { data } }: PayloadAction<ISetBackendDataPayload>) => {
      state.isLoading = false;
      state.data = data;
      state.search_airlines = {};
      state.filter_airlines = [];
      if (data && data?.airports) {
        state.search_airports = data.airports;
      }
      if (data && data?.airlines) {
        state.search_airlines = data.airlines;
        // @ts-ignore
        state.filter_airlines = data.filter_airlines;
      }
    },
    filterBackendData: (state, { payload: { key, value } }: PayloadAction<ISetFilterBackendDataPayload>) => {
      let filters = {};
      let search_data = localStorage.getItem('search_data');
      if (search_data) {
        search_data = JSON.parse(search_data);
        // @ts-ignore
        if (search_data?.filters) {
          // @ts-ignore
          filters = search_data?.filters;
        }
      }
      switch (key) {
        case 'airlines': {
          state.airlines = value;
          break;
        }
        case 'transfers': {
          state.transfers_rules = value;
          if (value[0] === 'none') {
            state.transfers_count = 0;
          } else if (value[0] === 'one') {
            state.transfers_count = 1;
          } else {
            state.transfers_count = 2;
          }
          if (value.includes('more')) {
            state.transfers_more_than = true;
          } else {
            state.transfers_more_than = false;
          }
          break;
        }
        case 'time_trip_to': {
          state.time_trip_to_min = value[0];
          state.time_trip_to_max = value[1];
          break;
        }
        case 'time_trip_from': {
          state.time_trip_from_min = value[0];
          state.time_trip_from_max = value[1];
          break;
        }
        case 'time_back_to': {
          state.time_back_to_min = value[0];
          state.time_back_to_max = value[1];
          break;
        }
        case 'time_back_from': {
          state.time_back_from_min = value[0];
          state.time_back_from_max = value[1];
          break;
        }
      }
      // @ts-ignore
      filters[key] = value;
      // @ts-ignore
      search_data['filters'] = filters;
      localStorage.setItem('search_data', JSON.stringify(search_data));
    },
    resetFilters: (state, { }: PayloadAction) => {
      state.airlines = [];
      state.transfers_rules = [];
      state.transfers_count = 0;
      state.transfers_more_than = true;
      state.time_trip_from_min = 0;
      state.time_trip_from_max = 1440;
      state.time_trip_to_min = 0;
      state.time_trip_to_max = 1440;
      state.time_back_from_min = 0;
      state.time_back_from_max = 1440;
      state.time_back_to_min = 0;
      state.time_back_to_max = 1440;
    },
    resetSearchData: (state, { }: PayloadAction) => {
      state.search_airlines = {};
      state.search_airports = {};
      state.filter_airlines = [];
      state.data = {
        tickets: [],
        airports: [],
        airlines: [],
        filter_airlines: []
      }
    },
    resetSearch: () => initialState
  },
});

export default searchSlice.reducer;
export const { setLoading, setSearch, setType, swapRoutes, setPassengers, saveBackendData, filterBackendData, resetFilters, resetSearchData, resetSearch } = searchSlice.actions;

export const searchSelector = (state: RootState) => state.search;
