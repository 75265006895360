import { FunctionComponent } from 'react';
import clsx from 'clsx';
import styles from './CTAMessage.module.scss';
import InfoMessage from '../InfoMessage/InfoMessage';
import Button from '../Button/Button';
import { useNavigate } from 'react-router-dom';

interface CTAMessageProps {
  title: string;
  subtitle: string;
  buttonText?: string;
  buttonIconBefore?: string;
  buttonIconAfter?: string;
  className?: string;
  onClick?: () => void;
}

const CTAMessage: FunctionComponent<CTAMessageProps> = ({
  title,
  subtitle,
  buttonText,
  buttonIconBefore,
  buttonIconAfter,
  className,
  onClick,
}) => {
  return (
    <div className={clsx(styles.root, className)}>
      <InfoMessage title={title} subtitle={subtitle} />
      {buttonText && (
        <Button iconBefore={buttonIconBefore} iconAfter={buttonIconAfter} className={styles.button} onClick={onClick}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default CTAMessage;
