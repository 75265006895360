import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../Button';
import CTAMessage from '../../CTAMessage/CTAMessage';
import Select from '../../Input/Select/Select';
import { partnerApi } from '../../../store/api/partnerService';
import { modalSliceSelector, updateModal } from '../../../store/slices/modal';
import { partnerSelector, updatePagination, updatePeriod } from '../../../store/slices/partner';
import { userSelector } from '../../../store/slices/user';
import { useAppDispatch } from '../../../store/store';
import { formatPrice } from '../../../utils/formatting';
import LinksTable from './LinksTable/LinksTable';
import styles from './PartnershipLinks.module.scss';
import TotalCard from './TotalCard/TotalCard';
import LoaderMessage from '../../LoaderMessage/LoaderMessage';
import EarningsCard from './EarningsCard/EarningsCard';

import i18n from 'i18next';
import {useTranslation} from "react-i18next";

export const PARTNER_BONUS_ON_RUB = 80; // 80 рублей

const PartnershipLinks = () => {
  const { t } = useTranslation();

  const sortOptions = [
    { id: 0, label: t('partnershipLinks.sortOptions.allTime'), value: 'all' },
    { id: 1, label: t('partnershipLinks.sortOptions.today'), value: 'today' },
    { id: 2, label: t('partnershipLinks.sortOptions.sevenDays'), value: 'week' },
    { id: 3, label: t('partnershipLinks.sortOptions.thirtyDays'), value: 'month' },
  ];
  const dispatch = useAppDispatch();
  const { token } = useSelector(userSelector);
  const { partner, pagination } = useSelector(partnerSelector);
  const [sort, setSort] = useState(sortOptions[0].value);
  const modals = useSelector(modalSliceSelector);

  const { data: links, isLoading, isError, refetch } = partnerApi.useGetLinksQuery({ token, date: sort });

  useEffect(() => {
    refetch();
  }, [modals]);

  const handleOpenCreateLink = () => {
    dispatch(updateModal({ createPartnerLink: true }));
  };
  const handleSortChange = (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | React.FocusEvent<Element, Element> | null,
    value: string | null
  ) => {
    setSort(value || '');
    dispatch(updatePeriod({ period: value || 'all' }));
  };
  const handleChangePage = (page: number) => {
    dispatch(updatePagination({ page }));
  };

  if (isLoading) {
    return <LoaderMessage title={t('common.loading')} />;
  }

  let passengersCount = partner.passengersTotal || 0;
  let ordersTotal = partner.ordersTotal || 0;
  let incomeTotal = partner.incomeTotal || 0;

  // @ts-ignore
  if (links?.data?.length > 0 && partner.incomeTotal===0) {
    passengersCount = 0;
    ordersTotal = 0;
    incomeTotal = 0;

    links?.data.map(link => {
      // @ts-ignore
      passengersCount += link.passengersTotal || 0;
      ordersTotal += link.ordersTotal || 0;
      incomeTotal += link.incomeTotal || 0;
    });
  }

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h1 className={styles.h1}>{t('partnershipLinks.title')}</h1>
          <Button iconBefore="/icons/plusWhite.svg" onClick={handleOpenCreateLink}>
            {t('partnershipLinks.newLinkButton')}
          </Button>
        </div>
        <p className={styles.description}>
          {t('partnershipLinks.minPayment', { amount: PARTNER_BONUS_ON_RUB })}
        </p>
        {links?.data.length ? (
          <>
            <div className={styles.row}>
              {partner && (
                <div className={styles.total}>
                  <TotalCard
                    icon="/icons/male.svg"
                    name={t('partnershipLinks.passengers')}
                    value={passengersCount}
                    className={styles.passengers}
                  />
                  <TotalCard
                    icon="/icons/cart.svg"
                    name={t('partnershipLinks.orders')}
                    value={ordersTotal}
                  />
                  <TotalCard
                    icon="/icons/tickRound.svg"
                    name={t('partnershipLinks.income')}
                    value={`+${formatPrice(incomeTotal)}`}
                    className={styles.income}
                  />
                  {partner.display_expected_payout && (
                    <EarningsCard
                      name={t('partnershipLinks.expectedPayout')}
                      value={`+${formatPrice(partner.expected_payout)}`}
                      className={styles.income}
                    />
                  )}
                </div>
              )}
              <div className={styles.wrapper}>
                <Select
                  className={styles.select}
                  options={sortOptions}
                  onChange={handleSortChange}
                  value={sort}
                  label=""
                  disableScroll
                />
              </div>
            </div>
            <LinksTable data={links} changePage={handleChangePage} />
          </>
        ) : (
          <CTAMessage
            title={t('partnershipLinks.noLinksTitle')}
            subtitle={t('partnershipLinks.noLinksSubtitle')}
          />
        )}
      </div>
    </div>
  );
};
export default PartnershipLinks;
