import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {IAirlines, ProposalsEntity, Country} from '../../types/backend';
import {Airport, City, ITicket, ITicketBackend, TicketType} from '../../types/ticket';
import { ticketAdapter } from '../../utils/adapters';
import {BASE_URL} from "../../utils/api";
interface SearchParams {
  airport_from: string;
  airport_to: string;
  date: string;
  date_back?: string;
  adults: number;
  children: number;
  babies: number;
  type: TicketType;
  price_from?: number;
  price_to?: number;
  transfers_count?: number;
  transfers_more_than?: boolean;
  transfer_duration_from?: number;
  transfer_duration_to?: number;
  airlines?: [];
  time_trip_form?: number;
  time_trip_to?: number;
  time_back_from?: number;
  time_back_to?: number;
}

// export type ISearchResult = { trip: TripEntity[]; back?: BackEntity[] | null; tickets: ITicket[] };
export type ISearchEntryOld = { trip: ProposalsEntity; back?: ProposalsEntity | null; ticket: ITicket };
// export type ISearchResult = ISearchEntry[];

export type ISearchEntry = ITicketBackend;
export type ISearchResult = { tickets?: ITicketBackend[], airports?: Airport[], airlines?: IAirlines[], trip_airports?: { from: IAirlines, to: IAirlines }, filter_airlines?: string[] }

const basicHeaders = { Accept: 'application/json', 'Content-type': 'application/json' };

export const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['Tickets'],
  endpoints: builder => ({
    getAllAirports: builder.query<Airport[], string>({
      query: () => 'airport-list',
      keepUnusedDataFor: 3600,
    }),
    searchAirports: builder.query<[], string>({
      query: s => ({ url: 'airport/search', params: { s } }),
      keepUnusedDataFor: 3600,
    }),
    getAirportsByCode: builder.query<Airport[], string>({
      query: code => ({ url: 'airport-get-by-code', params: { code } }),
      keepUnusedDataFor: 3600,
    }),
    getAirportsByName: builder.query<Airport[], string>({
      query: name => ({ url: 'airport-get-by-name', params: { name } }),
      keepUnusedDataFor: 3600,
    }),
    getAllCities: builder.query<City[], string>({
      query: () => 'city-list',
      keepUnusedDataFor: 3600,
    }),
    getCitiesByCode: builder.query<City[], string>({
      query: code => ({ url: 'city-get-by-code', params: { code } }),
      keepUnusedDataFor: 3600,
    }),
    getCitiesByName: builder.query<City[], string>({
      query: name => ({ url: 'city-get-by-name', params: { name } }),
      keepUnusedDataFor: 3600,
    }),
    search: builder.query<ISearchResult, SearchParams>({
      query: params => ({ url: 'search', params, headers: basicHeaders }),
      transformResponse: ticketAdapter
    }),
    getAllCountries: builder.query<[], {}>({
      query: ({}) => 'countries',
      keepUnusedDataFor: 3600,
    }),
    searchCountries: builder.query<[], string>({
      query: s => ({ url: 'countries/search', params: { s } }),
      keepUnusedDataFor: 3600,
    }),
    getCountryByCode: builder.query<[], string>({
      query: code => ({ url: `countries/get/${code}` }),
      keepUnusedDataFor: 3600,
    }),
    getCurrencyRateByCode: builder.query<[], string>({
      query: code => ({ url: `exchange-rates/${code}` }),
      keepUnusedDataFor: 3600,
    }),
  }),
});

export const {
  useGetAllAirportsQuery,
  useSearchAirportsQuery,
  useGetAirportsByCodeQuery,
  useGetAirportsByNameQuery,
  useGetAllCitiesQuery,
  useGetCitiesByCodeQuery,
  useGetCitiesByNameQuery,
  useLazySearchQuery,
  useSearchQuery,
  useGetAllCountriesQuery,
  useSearchCountriesQuery,
  useGetCountryByCodeQuery,
  useGetCurrencyRateByCodeQuery,
} = searchApi;
