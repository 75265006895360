import {ClickAwayListener, TextField} from '@mui/material';
import {useState, ChangeEventHandler, useEffect, KeyboardEventHandler, useRef} from 'react';
import { useGetAllCountriesQuery, useGetCountryByCodeQuery } from '../../../../store/api/searchService';
import { useDebounce } from '../../../../utils/useDebounce';
import styles from './PersonalCitizenship.module.scss';
import {Country} from "../../../../types/backend";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

type Props = {
  className?: string;
  label: string;
  field: any;
  updateField: any;
  errors: any;
};

const PersonalCitizenship = ({ label, field, updateField, errors }: Props) => {
  const textRef = useRef<HTMLInputElement | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [fieldValue, setValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const [countries, setCountries] = useState([]);

  const country = (field.value || '');
  const countriesListRaw = useGetAllCountriesQuery({});
  const debouncedValue = useDebounce(searchText, 350);
  const currentCountry = useGetCountryByCodeQuery(country, { skip: !country.length || !/^[A-Z]{2}$/g.test(country) });
  const { t } = useTranslation();

  useEffect(() => {
    if (countriesListRaw.data) {
      setCountries(countriesListRaw.data || []);
    }
    let temp = JSON.parse(JSON.stringify(currentCountry.data ?? '[]'));
    if (temp && temp.length > 0) {
      setValue(i18n.language.startsWith('ru') ? temp[0].country : temp[0].country_en)
    }
  }, [countriesListRaw, currentCountry]);
  
  const handleChange = (v: any) => {
    const inputValue = (i18n.language.startsWith('ru') ? v?.country : v?.country_en) || '';
    /*if (textRef?.current) {
      textRef.current.value = inputValue;
    }*/
    setValue(inputValue);
    field.onChange((v?.code || ''));
    setIsUpdating(true);
    setSearchText('');
    handleClose();
  };
  const handleFocus = () => {
    handleOpen();
    setIsUpdating(true);
  };
  const handleOpen = () => setIsOpen(true);
  const handleClose = (e?: MouseEvent | TouchEvent) => {
    if (textRef?.current) {
      if (e?.target === textRef.current) return;
      setIsOpen(false);
      setIsUpdating(false);
    }
  };

  const handleStartEdit = () => {
    setIsUpdating(true);
    handleOpen();
  };

  const handleTextChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
    const { value } = e.currentTarget;
    setValue(value);
    setSearchText(value);
    updateField(field.name, value);
  };
  const handleKey: KeyboardEventHandler = e => {
    if (e.key == 'Tab') handleClose();
    if (e.key == 'Escape') handleClose();
  };
  
  const popularList = [
    { country: 'Россия', country_en: 'Russia', code: 'RU' },
    { country: 'Беларусь', country_en: 'Belarus', code: 'BY' },
    { country: 'Украина', country_en: 'Ukraine', code: 'UA' },
    { country: 'Казахстан', country_en: 'Kazakhstan', code: 'KZ' }
  ];

  const outputCounty = (selected: string, item: any, click: boolean = true, check: boolean = false) => {
    if (check && selected === item.code) return;
    return (
      <li
          className={styles.option}
          onClick={ () => click && item ? handleChange(item) : null }
      >
        {(
          <>
            <div className={styles.title}>
              { selected === item.code ? <img className={styles.icon} alt="" src="/icons/check.svg" /> : <div className={styles.icon} /> }
              <div className={styles.value}>{i18n.language.startsWith('ru') ? item.country : item.country_en}</div>
            </div>
            <div className={styles.code}>{item.code}</div>
          </>
        )}
      </li>
    )
  }

  let countriesList: Country[] = JSON.parse(JSON.stringify(countries));
  if (countriesList && countriesList.length > 0 && isUpdating && debouncedValue) {
    const searchValue = debouncedValue.trim().toLowerCase();
    countriesList = countriesList.filter((item: Country) => {
      return item.code.toLowerCase().includes(searchValue)
        || item.country.toLowerCase().includes(searchValue)
        || item.country_en.toLowerCase().includes(searchValue);
    });
  }

  return (
    <div className={styles.root}>
      <TextField
        variant="filled"
        classes={{ root: styles.textField }}
        inputRef={textRef}
        InputProps={{ classes: { root: styles.inputRoot } }}
        InputLabelProps={{ shrink: isUpdating || !!fieldValue }}
        value={isUpdating ? searchText : fieldValue}
        label={label}        
        onChange={ (e) => {
          handleTextChange(e);
          // field.onChange(e.target.value)
        }}
        onClick={handleStartEdit}
        onKeyDown={handleKey}
        onFocus={handleFocus}
        defaultValue={ fieldValue }
        name={ field.name }
        error={errors?.[field.name]}
        helperText={errors?.[field.name] ? errors?.[field.name].message : ''}
      />
      <img onClick={handleFocus} className={styles.iconsArrow} alt="" src="/icons/arrowDown.svg" />
      {isOpen && (
        <ClickAwayListener onClickAway={handleClose}>
          <div className={styles.paper}>
            <ul className={styles.listbox}>
              <>
                { !searchText.trim().length && (
                  <>
                    <li className={styles.option + ' ' + styles.group}>
                      <div className={styles.title}>
                        <div className={styles.value + ' ' + styles.bold}>{t('personalCitizenship.popular')}</div>
                      </div>
                    </li>
                    { popularList.map((item) => {
                      return outputCounty(country, item, true)
                    }) }
                    <li className={styles.option + ' ' + styles.group}>
                      <div className={styles.title}>
                        <div className={styles.value + ' ' + styles.bold}>{t('personalCitizenship.allCountries')}</div>
                      </div>
                    </li>
                  </>
                )}
                {
                  countriesList && countriesList.length > 0
                    ?
                    countriesList.map((item) => {
                      return outputCounty(country, item, true)
                    })
                    :
                    <li className={styles.option} style={{ minHeight: 30, fontSize: '16px' }} key="noResults">{t('personalCitizenship.noResults')}</li>
                }
              </>
            </ul>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default PersonalCitizenship;
