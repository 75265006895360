import styles from './LinksTable.module.scss';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { IPartnerLink } from '../../../../types/partner';
import { formatPrice } from '../../../../utils/formatting';
import { MouseEvent, useEffect, useState } from 'react';
import Pagination from '../../../Pagination/Pagination';
import Card from '../../../Card/Card';
import { useNavigate } from 'react-router-dom';
import { ApiPaginationResponse } from '../../../../types/backend';
import Button from '../../../Button';
import clsx from 'clsx';
import {useTranslation} from "react-i18next";
import i18n from "i18next";

interface Props {
  data: ApiPaginationResponse<IPartnerLink>;
  changePage: (page: number) => void;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type OrderBy = 'asc' | 'desc' | 'default';

function getComparator<Key extends keyof any>(
  order: OrderBy,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly Partial<T>[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof IPartnerLink;
  label: string;
  numeric: boolean;
}


interface EnhancedTableProps {
  onRequestSort: (event: MouseEvent, property: keyof IPartnerLink) => void;
  order: OrderBy;
  orderBy: keyof IPartnerLink;
}

export const renderArrows = (orderBy: keyof IPartnerLink, order: OrderBy, id: keyof IPartnerLink, onRequestSort: (event: MouseEvent, property: keyof IPartnerLink) => void) => {
  if (order === 'default' || orderBy !== id){
    return <img className={styles.sort_arrow} src={'/icons/sort_default.svg'} onClick={(event: MouseEvent) => {
      onRequestSort(event, id);
    }}/>
  } else if (order === 'asc'){
    return <img className={styles.sort_arrow} src={'/icons/sort_desc.svg'} onClick={(event: MouseEvent) => {
      onRequestSort(event, id);
    }}/>
  } else {
    return <img className={styles.sort_arrow} src={'/icons/sort_asc.svg'} onClick={(event: MouseEvent) => {
      onRequestSort(event, id);
    }}/>
  }
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: i18n.t('partnershipLinksTable.name'),
    },
    {
      id: 'ordersTotal',
      numeric: true,
      disablePadding: false,
      label: i18n.t('partnershipLinksTable.ordersTotal'),
    },
    {
      id: 'passengersTotal',
      numeric: true,
      disablePadding: false,
      label: i18n.t('partnershipLinksTable.passengersTotal'),
    },
    {
      id: 'incomeTotal',
      numeric: true,
      disablePadding: false,
      label: i18n.t('partnershipLinksTable.incomeTotal'),
    },
  ];


  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
            {headCell.id !== 'name' && renderArrows(orderBy, order, headCell.id, onRequestSort)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const LinksTable = ({ data, changePage }: Props) => {
  const { t } = useTranslation();
  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: i18n.t('partnershipLinksTable.name'),
    },
    {
      id: 'ordersTotal',
      numeric: true,
      disablePadding: false,
      label: i18n.t('partnershipLinksTable.ordersTotal'),
    },
    {
      id: 'passengersTotal',
      numeric: true,
      disablePadding: false,
      label: i18n.t('partnershipLinksTable.passengersTotal'),
    },
    {
      id: 'incomeTotal',
      numeric: true,
      disablePadding: false,
      label: i18n.t('partnershipLinksTable.incomeTotal'),
    },
  ];

  const navigate = useNavigate();
  const {
    data: links,
    meta: { current_page, last_page, per_page },
  } = data;
  const [order, setOrder] = useState<OrderBy>('default');
  const [orderBy, setOrderBy] = useState<keyof IPartnerLink>('id');
  const [sortedLinks, setSortedLinks] = useState(links);
  const [isSortWindowOpened, setSortWindowOpened] = useState(false);

  useEffect(() => {
    setSortedLinks(links)
  }, [links]);

  const handleRequestSort = (event: MouseEvent<Element>, property: keyof IPartnerLink, currentOrder?: OrderBy) => {
    const isAsc = orderBy === property && order === 'asc';
    const isDesc = orderBy === property && order === 'desc';
    if (currentOrder) {
      setOrder(currentOrder)
    } else {
      setOrder(isAsc ? 'desc' : isDesc ? 'default' : 'asc');
    }
    const sortedLinks = [...links];
    setOrderBy(property);
    if (isAsc) {
      // @ts-ignore
      setSortedLinks(sortedLinks.sort((a, b) => b[property] - a[property]));
    } else if (isDesc) {
      setSortedLinks(links);
    } else {
      // @ts-ignore
      setSortedLinks(sortedLinks.sort((a, b) => a[property] - b[property]));
    }
  };

  const handleClick = (event: MouseEvent, row: IPartnerLink) => {
    navigate(`/lk/partnership/${row.id}`);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = current_page > 1 ? Math.max(0, per_page - links.length) : 0;

  if (isSortWindowOpened) {
    return (
      <div className={clsx(styles.sort_window, isSortWindowOpened ? styles.sort_window_opened : null)}>
        <div className={styles.sort_window_title}>
          {t('linksTable.sort')}
          <img src={'/icons/close_grey.svg'} onClick={(event) => {
            handleRequestSort(event, 'id', 'default');
            setSortWindowOpened(false);
          }}/>
        </div>
        <div className={styles.sort_types}>
          {headCells.filter(cell => cell.id !== 'name').map(cell =>
            <div onClick={(event) => handleRequestSort(event, cell.id)} className={styles.sort_types_type}>
              <span>{cell.label}</span>
              <span>{renderArrows(orderBy, order, cell.id, handleRequestSort)}</span>
              {orderBy === cell.id && (
                <p className={styles.sort_types_order}>{order === 'desc' ?
                  t('linksTable.descending') : order === 'asc' ? t('linksTable.ascending') : ''}
                </p>
              )}
            </div>
          )}
        </div>
        <Button className={styles.sort_window_button} onClick={() => setSortWindowOpened(false)}>
          {t('linksTable.apply')}
        </Button>
      </div>
    )
  }

  return (
    <Card className={styles.root}>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort}/>
          <TableBody>
            {/* {stableSort(links, getComparator(order, orderBy)).slice(page * data.per_page, page * data.per_page + data.per_page) */}
            {sortedLinks.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  onClick={event => handleClick(event, row)}
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell component="th" id={labelId} scope="row">
                    <div className={styles.link_name}>
                      <div className={styles.link_name_image}>
                        <img src={'/icons/chain.svg'}/>
                      </div>
                      <div className={styles.link_name_link}>
                        <p className={styles.link_name_link_name}>{row.name}</p>
                        <p className={styles.link_name_link_value}>{row.link}</p>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="right">{row.ordersTotal || 0}</TableCell>
                  <TableCell align="right"><img src={'/icons/person_grey.svg'}/>{row.passengersTotal || 0}</TableCell>
                  <TableCell align="right">+{formatPrice(row.incomeTotal || 0)}</TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.links_mobile}>
        <Button className={styles.links_mobile_button} iconBefore="/icons/sort_default.svg" onClick={() => setSortWindowOpened(true)}>
          {t('linksTable.sort')}
        </Button>
        {
        sortedLinks.map(link => (
          <div className={styles.links_mobile_link} onClick={event => handleClick(event, link)}>
            <div className={styles.link_name}>
              <div className={styles.link_name_image}>
                <img src={'/icons/chain.svg'}/>
              </div>
              <div className={styles.link_name_link}>
                <p className={styles.link_name_link_name}>{link.name}</p>
                <p className={styles.link_name_link_value}>{link.link}</p>
              </div>
            </div>
            <div className={styles.links_mobile_link_info}>
              <div className={styles.links_mobile_link_info_item}>
                <p className={styles.label}>{t('linksTable.orders')} </p>
                <p className={styles.value}>{link.ordersTotal}</p>
              </div>
              <div className={styles.links_mobile_link_info_item}>
                <p className={styles.label}>{t('linksTable.passengers')} </p>
                <img src={'/icons/person_grey.svg'}/>
                <span className={styles.value}>{link.passengersTotal}</span>
              </div>
              <div className={styles.links_mobile_link_info_item}>
                <p className={styles.label}>{t('linksTable.income')} </p>
                <p className={styles.value}>+{formatPrice(link.incomeTotal || 0)}</p>
              </div>
            </div>
          </div>
        ))
        }
      </div>
      <Pagination
        className={styles.pagination}
        currentPage={current_page}
        lastPage={last_page}
        changePage={changePage}
      />
      {/* <TablePagination
          data.per_pageOptions={[10, 15, 20]}
          labeldata.per_page="На странице"
          component="div"
          count={links.length}
          data.per_page={data.per_page}
          page={page}
          onPageChange={handleChangePage}
          ondata.per_pageChange={handleChangedata.per_page}
        /> */}
    </Card>
  );
};

export default LinksTable;
