import { FunctionComponent, useEffect, useRef, useState } from 'react';
import InfoMessage from '../InfoMessage/InfoMessage';
import styles from './LoaderMessage.module.scss';
import {useTranslation} from "react-i18next";

type Props = {
  title?: string;
  ms?: number;
  search?: boolean;
};

const LoaderMessage = (props: Props) => {
  const [loading, setLoading] = useState(0);
  let interval = useRef<NodeJS.Timer | null>(null);
  const { t } = useTranslation();

  const clear = () => {
    if (!interval.current) return;
    clearInterval(interval.current);
  };

  useEffect(() => {
    interval.current = setInterval(() => {
      setLoading(s => s + 10);
    }, (props.ms ? props.ms : 400));

    // return clear;
  }, []);

  useEffect(() => {
    // if (loading >= 100) clear();
    if (props.search) {
      if (loading >= 100) {
        clear();
        interval.current = setInterval(() => {
          setLoading(s => s - 10);
        }, (props.ms ? props.ms : 400));
      } else if (loading <= 0) {
        clear();
        interval.current = setInterval(() => {
          setLoading(s => s + 10);
        }, (props.ms ? props.ms : 400));
      }
    }
    else if (loading >= 100) {
      clear();
    }
  }, [loading]);

  return (
    <div className={styles.root}>
      <InfoMessage title={props.title ? props.title : t('loader.findingTickets')} subtitle={t('loader.subtitle')} />
      <div className={styles.slider}>
        <div style={{ width: `${Math.min(100, loading)}%` }} className={styles.rectangleDiv} />
      </div>
    </div>
  );
};

export default LoaderMessage;
