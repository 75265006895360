import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IPassenger } from '../../types/passenger';
import { User } from '../slices/user';
import {BASE_URL, trimFields} from '../../utils/api';
import i18n from "i18next";

interface ApiError {
  email?: string[];
}
interface ApiResponse<T = undefined> {
  status: boolean;
  message?: string;
  errors?: ApiError;
  data?: T;
}
interface ApiUser {
  passenger: IPassenger;
  user: {
    email: string;
    phone?: string;
  };
}

const basicHeaders = { Accept: 'application/json', 'Content-type': 'application/json' };

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set('Accept-Language', i18n.language);

      return headers;
    },
  }),
  tagTypes: ['Passenger', 'User'],
  endpoints: builder => ({
    registerUser: builder.mutation<ApiResponse, { email: string; password: string; password_confirmation: string; url: string }>({
      query: body => ({ url: `register`, method: 'POST', headers: basicHeaders, body }),
    }),
    createPassword: builder.mutation<ApiResponse, { password: string; token: string }>({
      query: data => {
        const { token, ...body } = data;
        return {
          url: `user/set-password`,
          method: 'POST',
          headers: { ...basicHeaders, Authorization: `Bearer ${token}` },
          body,
        };
      },
    }),
    forgotPassword: builder.mutation<ApiResponse, { email: string; url: string }>({
      query: body => ({ url: `password-reset`, method: 'POST', headers: basicHeaders, body }),
    }),
    updatePassword: builder.mutation<ApiResponse, { password: string; newPassword: string; token: string }>({
      query: data => {
        const { token, ...body } = data;
        return {
          url: `update-password`,
          method: 'POST',
          headers: { ...basicHeaders, Authorization: `Bearer ${token}` },
          body,
        };
      },
    }),
    verifyEmail: builder.mutation<ApiResponse & { token: string }, { email: string; code: string }>({
      query: body => ({ url: `email-verify`, method: 'POST', headers: basicHeaders, body }),
    }),
    loginUser: builder.mutation<ApiResponse & { token: string, user: User }, { email: string; password: string }>({
      query: body => ({ url: `login`, method: 'POST', headers: basicHeaders, body }),
    }),
    addPassenger: builder.mutation<IPassenger, IPassenger & { token: string }>({
      query: data => {
        const { token, ...body } = trimFields(data);
        return {
          url: `passenger`,
          method: 'POST',
          headers: { ...basicHeaders, Authorization: `Bearer ${token}` },
          body,
        };
      },
      invalidatesTags: ['Passenger'],
    }),
    savePassenger: builder.mutation<ApiResponse, IPassenger & { token: string }>({
      query: data => {
        const { token, ...body } = trimFields(data);
        return {
          url: `passenger/${body.id}`,
          method: 'PUT',
          headers: { ...basicHeaders, Authorization: `Bearer ${token}` },
          body,
        };
      },
      invalidatesTags: ['Passenger'],
    }),
    deletePassenger: builder.mutation<ApiResponse, { id: string; token: string }>({
      query: data => {
        const { token, id } = data;
        return {
          url: `passenger/${id}`,
          method: 'DELETE',
          headers: { ...basicHeaders, Authorization: `Bearer ${token}` },
        };
      },
      invalidatesTags: ['Passenger'],
    }),
    getPassengers: builder.query<IPassenger[], { token: string }>({
      query: ({ token }) => ({
        url: 'user-passengers',
        headers: { ...basicHeaders, Authorization: `Bearer ${token}` },
      }),
      providesTags: result =>
        result
          ? [
            ...result.map(({ id }) => ({ type: 'Passenger' as const, id: id.toString() })),
            { type: 'Passenger', id: 'LIST' },
          ]
          : [{ type: 'Passenger', id: 'LIST' }],
    }),
    getPassenger: builder.query<IPassenger, { id: string | number; token: string }>({
      query: ({ token, id }) => ({
        url: `passenger/${id}`,
        headers: { ...basicHeaders, Authorization: `Bearer ${token}` },
      }),
    }),
    getPersonalInfo: builder.query<User, { token: string }>({
      query: ({ token }) => ({
        url: 'user/info',
        headers: { ...basicHeaders, Authorization: `Bearer ${token}` },
        // credentials: 'include',
      }),
      transformResponse: (data: ApiUser) => ({ ...data.passenger, ...data.user }),
      providesTags: ['User'],
    }),
    savePersonalData: builder.mutation<User, User & { token: string }>({
      query: data => {
        const { token, ...body } = trimFields(data);
        return {
          url: `user-passenger`,
          method: 'POST',
          headers: { ...basicHeaders, Authorization: `Bearer ${token}` },
          body,
        };
      },
      invalidatesTags: ['User'],
    }),
    checkEmailExists: builder.mutation<{ exists: boolean }, { s: string, user_id?: number }>({
      query: ({ s, user_id }) => ({
        url: 'user/email-check',
        method: 'GET',
        headers: { ...basicHeaders },
        params: { s, user_id }
      })
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useCreatePasswordMutation,
  useForgotPasswordMutation,
  useVerifyEmailMutation,
  useLoginUserMutation,
  useUpdatePasswordMutation,
  useAddPassengerMutation,
  useSavePassengerMutation,
  useDeletePassengerMutation,
  useGetPassengersQuery,
  useGetPassengerQuery,
  useGetPersonalInfoQuery,
  useLazyGetPersonalInfoQuery,
  useSavePersonalDataMutation,
  useCheckEmailExistsMutation
} = userApi;
