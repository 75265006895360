import { useState } from 'react';
import Button from '../Button';
import styles from './CookiesBanner.module.scss';
import {useTranslation} from "react-i18next";

const CookiesBanner = () => {
  const [isAccepted, setIsAccepted] = useState((localStorage.getItem('cookie_banner') !== null ? true : false));
  const { t } = useTranslation();

  const handleAccept = () => {
    setIsAccepted(true);
    localStorage.setItem('cookie_banner', '1');
  }

  if (isAccepted) return null;

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <span className={styles.text}>
          {t('cookiesBanner.agreement')}
        </span>
        <Button className={styles.button} onClick={handleAccept}>
          {t('cookiesBanner.ok')}
        </Button>
      </div>
    </div>
  );
};
export default CookiesBanner;
